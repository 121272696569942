













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { blobDataPath } from '@/util/blob-data-path.utility';

@Component
export default class CityBGComponent extends Vue {
  @Prop()
  public modifierClass!: string;

  private xnavigatorImageFilePath = '';
  public get navigatorImageFilePath(): string {
    return this.xnavigatorImageFilePath;
  }
  public set navigatorImageFilePath(file: string) {
    this.xnavigatorImageFilePath = blobDataPath + file;
  }

  @Prop()
  public navigatorImageFilename;

  @Watch('navigatorImageFilename', { immediate: true })
  public onNavigatorImageChanged(navigatorImageFilename: string): void {
    if (navigatorImageFilename) {
      this.navigatorImageFilePath = navigatorImageFilename;
    } else if (!navigatorImageFilename && !this.$route.params.navigator) {
      this.navigatorImageFilePath = '/uploads/city_start_v3_4_8_2022_dec094490a.jpg';
    }
  }
}
