var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      class: _vm.position === 0 ? "first item-container" : "item-container",
      attrs: { height: "100%", tile: "", light: "" }
    },
    [
      _c("div", { staticClass: "item-content" }, [
        _c("h1", [_vm._v(_vm._s(_vm.itemInformation.title))]),
        _c("p", { staticClass: "text1" }, [
          _vm._v(" " + _vm._s(_vm.itemInformation.text1) + " ")
        ]),
        _c("p", { staticClass: "text2" }, [
          _c("b", [_vm._v(_vm._s(_vm.itemInformation.bold))]),
          _vm._v(" " + _vm._s(_vm.itemInformation.text2) + " ")
        ])
      ]),
      _vm.position === 0
        ? _c(_vm.itemInformation.img, { tag: "component" })
        : _vm.position === 1
        ? _c("v-img", {
            attrs: {
              contain: "",
              width: "44%",
              src: require("@/assets/city_flatline.png")
            }
          })
        : _vm.position === 2
        ? _c("v-img", {
            attrs: {
              contain: "",
              width: "44%",
              src: require("@/assets/ai_flatline.png")
            }
          })
        : _vm.position === 3
        ? _c("v-img", {
            attrs: {
              contain: "",
              width: "44%",
              src: require("@/assets/chat_flatline.png")
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }