import { render, staticRenderFns } from "./DataPrivacyContent.vue?vue&type=template&id=819deccc&scoped=true"
import script from "./DataPrivacyContent.vue?vue&type=script&lang=ts"
export * from "./DataPrivacyContent.vue?vue&type=script&lang=ts"
import style0 from "./DataPrivacyContent.vue?vue&type=style&index=0&id=819deccc&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "819deccc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/app/b-i-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('819deccc')) {
      api.createRecord('819deccc', component.options)
    } else {
      api.reload('819deccc', component.options)
    }
    module.hot.accept("./DataPrivacyContent.vue?vue&type=template&id=819deccc&scoped=true", function () {
      api.rerender('819deccc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/contact/DataPrivacyContent.vue"
export default component.exports