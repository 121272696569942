var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-page-4-container" },
    [
      _c(
        "div",
        [
          _c("ContactHeaderBarComponent", {
            on: {
              "navigate-back": function($event) {
                return _vm.$emit("navigate-form", "ContactEntryP2")
              }
            }
          })
        ],
        1
      ),
      _c("DataPrivacyContent", { staticClass: "privacy" }),
      _c(
        "div",
        { staticClass: "switch-content" },
        [
          _c("b", [
            _vm._v(
              " " + _vm._s(_vm.DCSLanguageService.translate("InterestedInMore"))
            )
          ]),
          _c("v-switch", {
            attrs: {
              color: "green",
              inset: "",
              label: _vm.DCSLanguageService.translate("OptInEmail")
            },
            on: {
              change: function($event) {
                return _vm.sendToStore($event, "optIn")
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "actions-container" },
        [
          _c("v-divider"),
          _c(
            "v-btn",
            {
              staticClass: "white--text green elevation-0",
              attrs: { rounded: "" },
              on: {
                click: function($event) {
                  return _vm.addToContactFormValues()
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.DCSLanguageService.translate("Submit")) + " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }