





































































import { Vue, Component, Watch } from "vue-property-decorator";
import { dragscroll } from "vue-dragscroll";
import VueScrollTo from "vue-scrollto";

import { fromEvent, interval, merge, ReplaySubject } from "rxjs";
import { debounce, debounceTime, takeUntil } from "rxjs/operators";

import CityBGComponent from "@/components/core/backgrounds/CityBG.vue";
import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import CityZoomerComponent from "@/components/city/CityZoomer.vue";
import AnniversaryPopupComponent from "@/components/city/AnniversaryPopup.vue";
import ZoomedNavigatorsComponent from "@/components/city/ZoomedNavigators.vue";
import NavigatorsComponent from "@/components/city/navigators/Navigators.vue";
import HelpModalComponent from "@/components/help/help-modal/HelpModal.vue";
import { MetaInfo } from "vue-meta";
import { ComponentOptions } from "vue";

@Component({
  components: {
    CityBGComponent,
    CityDarkBGComponent,
    CityZoomerComponent,
    AnniversaryPopupComponent,
    ZoomedNavigatorsComponent,
    NavigatorsComponent,
    HelpModalComponent,
  },
  directives: {
    dragscroll: dragscroll,
  },
})
export default class City extends Vue {
  public static options: ComponentOptions<Vue> = {
    metaInfo: {
      title: 'Explore the Cityscape',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Discover Dow Building Science products by application in this interactive cityscape experience.',
        },
      ],
    },
  };

  public showModal = false;

  @Watch("$store.state.openModal", { immediate: true })
  public onStateChange(modalState: boolean): void {
    if (modalState) {
      this.showModal = true;
    }
  }

  private cityWrapperEl!: HTMLElement;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private mounted(): void {
    this.cityWrapperEl = this.$refs.cityWrapper as HTMLElement;

    const observable = fromEvent(window, "resize");
    const subscriber = observable.pipe(
      debounce(() => interval(200)),
      takeUntil(this.destroyed$)
    );
    const options = {
      container: ".city-wrapper",
      easing: "ease",
      lazy: true,
      offset: 880 - screen.width / 2,
      cancelable: false,
      x: true,
      y: false,
    };

    subscriber.subscribe(() => {
      options.offset = 880 - screen.width / 2; // this has to be calculated every time, not just when first mounted
      VueScrollTo.scrollTo(this.cityWrapperEl, 0, options);
    });

    VueScrollTo.scrollTo(this.cityWrapperEl, 0, options);
  }

  private destroyed(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
