var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-header-bar-container" },
    [
      _c(
        "div",
        { staticClass: "contact-header-bar-inner-container" },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("navigate-back")
                }
              }
            },
            [
              _c("v-icon", { staticClass: "primary--text" }, [
                _vm._v(" mdi-arrow-left ")
              ])
            ],
            1
          ),
          _c("h4", [
            _vm._v(_vm._s(_vm.DCSLanguageService.translate("ConnectTitle")))
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "first-divider--margin" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }