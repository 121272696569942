import { AxiosResponse } from "axios";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { serializeStrapiResponse } from "@/util/seralize.utility";
import { http } from "../http-client";

import { HelpModal } from "@/types";
import { DCSLanguageService } from "../dcs-language.service";

export class HelpModalService {
  private DCSLanguageService = new DCSLanguageService();

  public getHelpModal(): Observable<HelpModal> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;

    return defer(() => http.get(`dcs-language/help-modal/${languageID}`)).pipe(
      map((res: AxiosResponse<unknown>) => res.data),
      map((modal) => serializeStrapiResponse(modal) as HelpModal)
    );
  }
}
