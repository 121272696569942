import { AxiosResponse } from "axios";
import { combineLatest, defer, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { http } from "../http-client";
import { Navigator, SubNavigator } from "@/types";
import { urlReplaceSpacesAndLower } from "@/util/url-formatter.utility";
import { serializeStrapiResponse } from "@/util/seralize.utility";

import { DCSLanguageService } from "../dcs-language.service";

export class CityNavigatorService {
  private DCSLanguageService = new DCSLanguageService();

  public getNavigators(): Observable<Navigator[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() => http.get(`/dcs-language/navigators/${languageID}`)).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((navigators) =>
        navigators.map(
          (navigator) => serializeStrapiResponse(navigator) as Navigator
        )
      )
    );
  }

  /**
   * This call takes longer on the backend because it recursively goes through the navigators adding their innovation count to the navigator model
   *
   * @returns Navigators with the innovation count
   */
  public getNavigatorsWithInnovations(): Observable<Navigator[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() =>
      http.get(`/dcs-language/navigators-innovations/${languageID}`)
    ).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((navigators) =>
        navigators.map(
          (navigator) => serializeStrapiResponse(navigator) as Navigator
        )
      )
    );
  }

  public getNavigatorById(subNavigatorId: number): Observable<Navigator> {
    return defer(() => http.get(`/navigators/${subNavigatorId}`)).pipe(
      map((res: AxiosResponse<unknown>) => res.data),
      map((navigator) => serializeStrapiResponse(navigator) as Navigator)
    );
  }

  public getNavigatorListByIds(
    navigatorIdList: number[]
  ): Observable<Navigator[]> {
    if (!navigatorIdList) {
      return of([]);
    }

    const navigatorRequests = navigatorIdList.map((id) =>
      this.getNavigatorById(id)
    );
    return combineLatest(navigatorRequests);
  }

  public getSubNavigatorById(subNavigatorId: number): Observable<SubNavigator> {
    return defer(() => http.get(`/sub-navigators/${subNavigatorId}`)).pipe(
      map((res: AxiosResponse<unknown>) => res.data),
      map(
        (subNavigator) => serializeStrapiResponse(subNavigator) as SubNavigator
      )
    );
  }

  public getSubNavigatorListByIds(
    subNavigatorIdList: number[]
  ): Observable<SubNavigator[]> {
    if (!subNavigatorIdList) {
      return of([]);
    }

    const subNavigatorRequests = subNavigatorIdList.map((id) =>
      this.getSubNavigatorById(id)
    );
    return combineLatest(subNavigatorRequests);
  }

  public getSubNavigatorListByLabel(
    label: string
  ): Observable<SubNavigator | null> {
    if (!label) {
      return of(null);
    }

    return defer(() => http.get("/sub-navigators")).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((subNavigator) =>
        subNavigator.map(
          (subNavigator) =>
            serializeStrapiResponse(subNavigator) as SubNavigator
        )
      ),
      map(
        (subNavigator) =>
          subNavigator.find(
            (navigator) =>
              urlReplaceSpacesAndLower(navigator.identifier) === label
          ) || null
      )
    );
  }
}
