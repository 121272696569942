























































































import { Vue, Component } from "vue-property-decorator";

import HelpModalPageComponent from "../help-modal-page/HelpModalPage.vue";
import AnniversaryInfoComponent from "@/components/city/modal-components/AnniversaryInfo.vue";
import ModalCarouselItem from "@/components/city/modal-components/ModalCarouselItem.vue";

import { DCSLanguageService } from "@/services";
import { HelpModalService } from "@/services/strapi-content/help-modal.service";

const HelpModalProps = Vue.extend({
  props: {
    visible: { type: Boolean, default: false },
  },
});

@Component({
  components: {
    HelpModalPageComponent,
    ModalCarouselItem,
    AnniversaryInfoComponent,
  },
})
export default class HelpModalComponent extends HelpModalProps {
  private helpModalService = new HelpModalService();
  public DCSLanguageService = new DCSLanguageService();

  public activePage = 0;
  public nextButtonMessage = "Next";

  public pages: {}[] = [];

  public modalClosed(): void {
    this.$store.commit("UPDATE_OPENMODAL_STATE", false);
    this.$emit("close", false);
  }

  private async mounted(): Promise<void> {
    const res = await this.helpModalService.getHelpModal().toPromise();
    this.pages = res["pages"];
  }

  public nextPage(): void {
    this.activePage += 1;
    if (this.activePage === this.pages.length - 1) {
      this.nextButtonMessage = "Done";
    } else if (this.activePage > this.pages.length - 1) {
      this.modalClosed();
    }
  }

  public prevPage(): void {
    this.activePage -= 1;
    if (this.activePage < this.pages.length - 1) {
      this.nextButtonMessage = "Next";
    }
  }
}
