var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "navigator mx-2",
      class: {
        "navigator--point-left":
          _vm.navigator.position.pointDirection === "left",
        "navigator--point-right":
          _vm.navigator.position.pointDirection === "right",
        "navigator--innovation":
          _vm.isInnovationSelected && _vm.navigator.innovationCount > 0
      },
      style: {
        top: _vm.navigator.position.top
          ? _vm.navigator.position.top + "%"
          : "auto",
        right: _vm.navigator.position.right
          ? _vm.navigator.position.right + "%"
          : "auto",
        bottom: _vm.navigator.position.bottom
          ? _vm.navigator.position.bottom + "%"
          : "auto",
        left: _vm.navigator.position.left
          ? _vm.navigator.position.left + "%"
          : "auto"
      },
      attrs: { outlined: "", rounded: "", large: "" },
      on: { click: _vm.clickHandler }
    },
    [
      _vm.navigator.iconName
        ? _c(
            "v-icon",
            { staticClass: "ml-0 mr-4 nav-icon", attrs: { left: "" } },
            [_vm._v(" " + _vm._s(_vm.navigator.iconName) + " ")]
          )
        : _vm._e(),
      _c("div", [
        _c("p", { staticClass: "label" }, [
          _vm._v(" " + _vm._s(_vm.navigator.labelText) + " ")
        ]),
        _c("p", { staticClass: "innovation-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.navigator.productDetail
                  ? null
                  : _vm.navigator.innovationCount
              ) +
              " " +
              _vm._s(
                _vm.navigator.innovationCount === 1
                  ? _vm.DCSLanguageService.translate("Innovation")
                  : _vm.DCSLanguageService.translate("Innovations")
              ) +
              " "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }