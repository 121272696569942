var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isOnRootPath
    ? _c(
        "header",
        {
          staticClass: "header-nav",
          attrs: { role: "navigation", "aria-label": "Main" }
        },
        [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2 primary--text back-btn",
                  attrs: {
                    outlined: "",
                    rounded: "",
                    "x-large": "",
                    elevation: "6"
                  },
                  on: { click: _vm.handleToCityClick }
                },
                [
                  _vm.showExploreCity
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DCSLanguageService.translate("ExploreThe")
                            )
                        ),
                        _c("span", { staticClass: "teal--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.DCSLanguageService.translate("City"))
                          )
                        ])
                      ]
                    : [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" mdi-arrow-left ")
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.DCSLanguageService.translate("BackTo"))
                        ),
                        _c("span", { staticClass: "teal--text" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$store.state.city.labelText || "City")
                          )
                        ])
                      ]
                ],
                2
              )
            ],
            1
          ),
          _vm._m(0),
          _c("ContactButtonComponent")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "https://www.dow.com" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/logos/dow-diamond.svg") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }