export interface ContactEloqua {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  country: string;
  marketSegment: string[];
  reason: string;
  comments: string;
  role: string;
  projectSize: string;
  budgetType: string;
  purchaseProcessStep: string;
  optIn: string;
}

export class ContactEloquaRemapped {
  'elqFormName': '202101_DCS_EMEAI_HPB_BI-Connect';
  'elqSiteID': '279295639';
  'elqCustomerGUID': ''; // Required "hidden" field
  'elqCookieWrite': '0'; // Required "hidden" field

  'lead-details': string; // All consolidated input with returns between prop/vals (\n)
  'bi-connect-action': string; // Form event/purpose used down-stream with lead source. *Consolidate into Lead Details*

  'first-name': string;
  'last-name': string;
  'company': string;
  'country': string; // Must be a value identical to their system's list
  'emailAddress': string;
  'opt-in': string;
  'market-segment': string; // [marketSegment] Include in lead-details | Comma delimited string eg -> Building Envelope, Building Materials and Components, Commercial Glazing
  'scorecard_attribute1': string; // [projectSize] Include in lead-details
  'scorecard_attribute2': string; // [budgetType] Include in lead-details
  'scorecard_position': string; // [role] Include in lead-details | Role / Job function
  'scorecard_need': string; // [reason] Include in lead-details | Reason for contacting
  'scorecard_readiness': string; // [purchaseProcessStep] Include in lead-details | Where are you at in your purchasing process?
  'comments-questions': string; // [comments] Include in lead-details
  'scorecard_preference': string;

  constructor(eloquaForm: ContactEloqua) {
    this.elqFormName     = '202101_DCS_EMEAI_HPB_BI-Connect';
    this.elqSiteID       = '279295639';
    this.elqCustomerGUID = ''; // Required "hidden" field
    this.elqCookieWrite  = '0'; // Required "hidden" field

    // Props that do not come from the form itself.
    this['bi-connect-action'] = eloquaForm.reason; // TODO: Temporary, need more insight on what the lead may want that differs this field from 'scorecard_need'
    this['opt-in'] = eloquaForm.optIn;
    this['scorecard_preference'] = '' // TODO: Temporary, should be optional since in the future we may add to the form.

    this['first-name']           = eloquaForm.firstName;
    this['last-name']            = eloquaForm.lastName;
    this['company']              = eloquaForm.company;
    this['country']              = eloquaForm.country;
    this['emailAddress']         = eloquaForm.email;
    this['market-segment']       = eloquaForm.marketSegment.join(', ');
    this['scorecard_attribute1'] = eloquaForm.projectSize;
    this['scorecard_attribute2'] = eloquaForm.budgetType;
    this['scorecard_position']   = eloquaForm.role;
    this['scorecard_need']       = eloquaForm.reason;
    this['scorecard_readiness']  = eloquaForm.purchaseProcessStep;
    this['comments-questions']   = eloquaForm.comments;

    this.generateLeadDetails(eloquaForm);
  }

  private generateLeadDetails(eloquaForm: ContactEloqua): void {
    const today = new Date();
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    this['lead-details'] = `
      \n***Lead Details***
      \nData Source: HPB - B&I Connect Virtual Lead
      \nLead Model: HPB2
      \nB&I Connect Action: ${eloquaForm.reason}
      \nMarket Segment: ${eloquaForm.marketSegment}
      \nQ1 (Project Size): ${eloquaForm.projectSize}
      \nQ2 (Budget Type): ${eloquaForm.budgetType}
      \nQ3 (Position / Role): ${eloquaForm.role}
      \nQ4 (Need): ${eloquaForm.reason}
      \nQ5 (Readiness): ${eloquaForm.purchaseProcessStep}
      \nQ6 (Preference for Follow-up): N/A
      \nComments / Questions: ${eloquaForm.comments}
      \nDistributor Name: TODO
      \nDistributor Email: TODO
      \nDate: ${ today.toLocaleDateString("en-US", dateOptions) }
    `;
  }
}
