








































import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import VueIntercom from "vue-intercom";

import CityHeaderComponent from "@/components/core/headers/CityHeader.vue";
import GenericHeaderComponent from "./components/core/headers/GenericHeader.vue";
import MobileNavComponent from "@/components/core/MobileNav.vue";
import SideDrawerComponent from "@/components/core/SideDrawer.vue";

import CityBGComponent from "@/components/core/backgrounds/CityBG.vue";
import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import ContactComponent from "@/views/Contact/Contact.vue";

import { urlReplaceSpacesAndLower } from "./util/url-formatter.utility";

import { LocaleService, AdobeTrackingService } from "@/services";

type HeaderState = {
  isOnRoot: boolean;
  isExploreCity: boolean;
  isMobileHome: boolean;
  modalActive: boolean;
};

@Component({
  components: {
    MobileNavComponent,
    SideDrawerComponent,
    ContactComponent,
    CityBGComponent,
    CityDarkBGComponent,
  },
})
export default class AppComponent extends Vue {

  private localeService = new LocaleService();
  private fromRoute!: string;
  public intercom = new VueIntercom({
    appId: process.env.VUE_APP_INTERCOM_APP_ID,
  });

  private xheader:
    | typeof CityHeaderComponent
    | typeof GenericHeaderComponent = CityHeaderComponent;
  public get header():
    | typeof CityHeaderComponent
    | typeof GenericHeaderComponent {
    return this.xheader;
  }
  public set header(
    headerComponent: typeof CityHeaderComponent | typeof GenericHeaderComponent
  ) {
    this.xheader = headerComponent;
  }

  private xcontactFormOpen = false;
  public get contactFormOpen(): boolean {
    return this.xcontactFormOpen;
  }
  public set contactFormOpen(open: boolean) {
    this.xcontactFormOpen = open;
  }

  private xheaderState: HeaderState = {
    isOnRoot: false,
    isExploreCity: false,
    isMobileHome: false,
    modalActive: false,
  };
  public get headerState(): HeaderState {
    return this.xheaderState;
  }
  public set headerState(headerState: HeaderState) {
    this.xheaderState = headerState;
  }

  /**
   * If the user routes directly to something under /product or /list we need to darken the background on load
   * (we don't know which building it's on without the emitter)
   */

  @Watch("$route", { immediate: true })
  private onRouteChange(toRoute: Route, fromRoute: Route): void {
    this.fromRoute = fromRoute ? fromRoute.path : "/";

    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Page Viewed",
      page: {
        pageInfo: {
          site: window.location.host, // Hostname of site
          siteRegion: "us", // ISO region code
          siteLanguage: "en", // ISO language code
          pageURL: window.location.href, // Page URL
          pageName: toRoute.params.categoryLabel
            ? toRoute.params.categoryLabel
            : toRoute.params.name
            ? toRoute.params.name
            : toRoute.name, // Page name (recommend English only)
          pageType: toRoute.name, // Page type (template type, etc.)
          firstPublished: "n/a", // Date page first published
          lastUpdated: "n/a", // Date page last updated
        },
      },
    });

    this.handleIsOnCityPage(toRoute);
    this.handleIsOnProductOrTemplatePage(toRoute);
    this.handleIsOnWebinarsPage(toRoute);
    this.handleIsOnEventsPage(toRoute);
    this.handleIsOnMobilePage(toRoute);
    this.handleIsOnNewProductPage(toRoute);
    this.handleIsOnNewsPage(toRoute);
  }

  @Watch("$store.state.openModal", { immediate: false })
  public onStateChange(modalState: boolean): void {
    if (!modalState) {
      this.initIntercom();
    }
    this.headerState = {
      ...this.headerState,
      modalActive: modalState,
    };
  }

  mounted(): void {
    if (this.$route.path === "/" || this.$route.path === "/city" || this.$route.path === "/city/") {
      this.$store.commit("UPDATE_OPENSIDEDRAWER_STATE", true);
    }

    window.addEventListener("backButton", this.handleBrowserBackButton);
    window.addEventListener("popstate", this.handleBrowserBackButton);

    this.initAnalytics();

    this.$root.$on("contact-form", this.handleContactFormEvent);
  }

  beforeDestroy(): void {
    window.removeEventListener("backButton", this.handleBrowserBackButton);
    window.removeEventListener("popstate", this.handleBrowserBackButton);
    this.$root.$off("contact-form", this.handleContactFormEvent);
  }

  private handleIsOnCityPage(toRoute: Route): void {
    if (toRoute.path.includes("/city")) {
      this.headerState = {
        ...this.headerState,
        isOnRoot: false,
      };

      this.header = CityHeaderComponent;
    } else {
      this.header = GenericHeaderComponent;
    }
  }

  private handleIsOnProductOrTemplatePage(toRoute: Route): void {
    if (toRoute.path.includes("/product/") || toRoute.path.includes("/list/")) {
      this.headerState = {
        ...this.headerState,
        isOnRoot: false,
      };
      this.$store.commit("CITY_FORCE_DARK_BACKGROUND", true);
    } else {
      this.$store.commit("CITY_FORCE_DARK_BACKGROUND", false);
    }
  }

  private handleIsOnWebinarsPage(toRoute: Route): void {
    if (toRoute.path.includes("/webinars")) {
      this.headerState = {
        ...this.headerState,
        isExploreCity: true,
        isOnRoot: false,
      };
    }
  }

  private handleIsOnEventsPage(toRoute: Route): void {
    if (toRoute.path.includes("/events")) {
      this.headerState = {
        ...this.headerState,
        isExploreCity: true,
        isOnRoot: false,
      };
    }
  }

  private handleIsOnNewProductPage(toRoute: Route): void {
    if (toRoute.path.includes("/new-products")) {
      this.headerState = {
        ...this.headerState,
        isExploreCity: true,
        isOnRoot: false,
      };
    }
  }
  private handleIsOnNewsPage(toRoute: Route): void {
    if (toRoute.path.includes("/news")) {
      this.headerState = {
        ...this.headerState,
        isExploreCity: true,
        isOnRoot: false,
      };
    }
  }

  private handleIsOnMobilePage(toRoute: Route): void {
    if (toRoute.name === "Mobile") {
      this.$store.commit("UPDATE_MOBILE_STATE", true);

      this.headerState = {
        ...this.headerState,
        isMobileHome: true,
      };
    } else {
      this.headerState = {
        ...this.headerState,
        isMobileHome: false,
      };
    }
  }

  private handleBrowserBackButton(): void {
    if (
      this.fromRoute.startsWith("/product/") ||
      this.fromRoute.startsWith("/list/")
    ) {
      //handle this the same as a GenericHeader.vue back button click
      if (
        this.$store.state.city.navigateTo &&
        this.$store.state.city.navigateTo.id
      ) {
        const param = urlReplaceSpacesAndLower(
          `${this.$store.state.city.navigateTo.id}-${this.$store.state.city.navigateTo.identifier}`
        );
        const { innovation } = this.$route.query;
        const innovationParam = innovation ? "?innovation=true" : "";
        this.$router.push(`/city/navigator/${param}/${innovationParam}`);
      } else {
        //were coming from a direct link so we don't know where to go back to
        //send them to the landing page
        this.headerState.isOnRoot = true;
        this.$router.push("/");
      }
    } else if (this.fromRoute.startsWith("/city/navigator")) {
      //handle this the same as CityHeader.vue back button
      this.$root.$emit("city-header-back-triggered");
    } else {
      this.$router.push("/");
    }
  }

  private initIntercom(): void {
    // will be null for Europe on first load, and 'en' is Europe
    const locale = this.localeService.getLocale();
    if (
      String(process.env.VUE_APP_ENABLE_INTERCOM) == "true" &&
      (locale === null || (locale && locale.code === "en"))
    ) {
      this.intercom.boot({
        // eslint-disable-next-line
        vertical_padding: 80,
      });
    }
    this.intercom.hide();
  }

  private handleContactFormEvent(isOpen: boolean): void {
    if (isOpen) {
      const { fullPath } = this.$route;
      this.$router.push({
        path: "/contact",
        query: { ...this.$route.query, goBackUrl: fullPath },
      });
    } else {
      if (this.$route.query.goBackUrl) {
        this.$router.replace(this.$route.query.goBackUrl as string);
      } else {
        this.$router.replace("/city");
      }
    }

    this.contactFormOpen = isOpen;
  }

  private initAnalytics(): void {
    const adobeTracking = new AdobeTrackingService();
      adobeTracking.init();

    let timeout = 3000;

    function registerPageBottom(): void {
      setTimeout(function() {
        if (window['s'] && typeof window['s'].t === 'function') {
          window['s'].t();
        } else {
          registerPageBottom();
        }
      }, timeout);

      timeout = 100;
    }

    registerPageBottom();
  }
}
