



















import { Component, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

@Component
export default class ContactHeaderBarComponent extends Vue {
  public DCSLanguageService = new DCSLanguageService();
}
