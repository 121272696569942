var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-page-3-container" }, [
    _c(
      "div",
      [
        _c("ContactHeaderBarComponent", {
          on: {
            "navigate-back": function($event) {
              return _vm.$emit("navigate-form", "ContactEntryP2")
            }
          }
        }),
        _c("p", { staticClass: "input-label" }, [
          _vm._v(
            " " + _vm._s(_vm.DCSLanguageService.translate("WhatRole")) + " "
          )
        ]),
        _c("v-combobox", {
          attrs: {
            items: _vm.roles,
            placeholder: _vm.DCSLanguageService.translate("SelectAnOption"),
            filled: "",
            "single-line": "",
            outlined: "",
            value: this.$store.state.contact.role
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event, "role")
            }
          }
        }),
        _c("p", { staticClass: "input-label" }, [
          _vm._v(
            " " +
              _vm._s(_vm.DCSLanguageService.translate("WhatProjectSize")) +
              " "
          )
        ]),
        _c("v-combobox", {
          attrs: {
            items: _vm.projectSizes,
            placeholder: _vm.DCSLanguageService.translate("SelectAnOption"),
            filled: "",
            "single-line": "",
            outlined: "",
            value: this.$store.state.contact.projectSize
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event, "projectSize")
            }
          }
        }),
        _c("p", { staticClass: "input-label" }, [
          _vm._v(
            " " +
              _vm._s(_vm.DCSLanguageService.translate("WhatBudgetType")) +
              " "
          )
        ]),
        _c("v-combobox", {
          attrs: {
            items: _vm.budgetTypes,
            placeholder: _vm.DCSLanguageService.translate("SelectAnOption"),
            filled: "",
            "single-line": "",
            outlined: "",
            value: this.$store.state.contact.budgetType
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event, "budgetType")
            }
          }
        }),
        _c("p", { staticClass: "input-label" }, [
          _vm._v(
            " " +
              _vm._s(_vm.DCSLanguageService.translate("WherePurchaseProcess")) +
              " "
          )
        ]),
        _c("v-combobox", {
          attrs: {
            items: _vm.purchaseProcessSteps,
            placeholder: _vm.DCSLanguageService.translate("SelectAnOption"),
            filled: "",
            "single-line": "",
            outlined: "",
            value: this.$store.state.contact.purchaseProcessStep
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event, "purchaseProcessStep")
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "actions-container" },
      [
        _c("v-divider"),
        _c(
          "v-btn",
          {
            staticClass: "white--text green elevation-0",
            attrs: { rounded: "" },
            on: {
              click: function($event) {
                return _vm.addToContactFormValues()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.DCSLanguageService.translate("Next")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }