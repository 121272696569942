var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-container" },
    [
      _c("DropdownComponent", {
        attrs: {
          "v-if": _vm.selectedLocaleName,
          selected: _vm.selectedLocaleName,
          items: _vm.localeNames
        },
        on: {
          selectChange: function($event) {
            return _vm.setLocale($event)
          }
        }
      }),
      _vm.displayLanguageDropDown
        ? _c("DropdownComponent", {
            attrs: {
              selected: _vm.selectedLanguageName,
              items: _vm.languageNames
            },
            on: {
              selectChange: function($event) {
                return _vm.setLanguage($event)
              }
            }
          })
        : _vm._e(),
      _c("router-link", { attrs: { to: "/webinars" } }, [
        _vm._v(" " + _vm._s(_vm.languageService.translate("Webinars")) + " ")
      ]),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.connectClicked()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.languageService.translate("Connect")) + " ")]
      ),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.whatsNewClicked()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.languageService.translate("WhatsNew")) + " ")]
      ),
      _c("div", { staticClass: "vl" }),
      _c("MenuComponent")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }