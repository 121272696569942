
































import { Component, Vue } from "vue-property-decorator";

import ContactHeaderBarComponent from "@/components/contact/sub-components/ContactHeaderBar.vue";
import DataPrivacyContent from "@/components/contact/DataPrivacyContent.vue";

import { DCSLanguageService } from "@/services";

@Component({
  components: {
    DataPrivacyContent,
    ContactHeaderBarComponent,
  },
})
export default class ContactEntryP4 extends Vue {
  public DCSLanguageService = new DCSLanguageService();
  public addToContactFormValues(): void {
    const formValues = this.$store.getters.getP4FormValues;

    this.$emit("submit-form", { formValues: formValues, submit: true });
    this.$emit("navigate-form", "ContactEntryComplete");
  }

  public sendToStore(e, type): void {
    this.$store.commit("UPDATE_CONTACT_STATE", {
      type,
      value: e === true ? "on" : "",
    });
  }
}
