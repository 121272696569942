












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { blobDataPath } from '@/util/blob-data-path.utility';

@Component
export default class CityDarkBGComponent extends Vue {
  @Prop()
  public modifierClass!: string;

  public ximage = '';
  public get image(): string {
    return this.ximage;
  }
  public set image(path: string) {
    this.ximage = path;
  }

  @Prop()
  public navigatorImageDarkFilename?: string;

  @Watch('navigatorImageDarkFilename', { immediate: true })
  public onNavigatorImageChanged (filename: string): void {
    if (filename) {
      this.image = blobDataPath + filename;
    } else if (!filename && !this.$route.params.navigator) {
      this.image = blobDataPath + '/uploads/city_start_v3_4_8_2022_dec094490a.jpg';
    }
  }
}
