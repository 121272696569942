var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticStyle: { "box-shadow": "none" },
      attrs: {
        "content-class": "help-modal",
        "max-width": "840px",
        persistent: ""
      },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "card" },
        [
          _c("v-img", {
            staticClass: "dow-diamond",
            attrs: {
              "max-width": "115px",
              "max-height": "39px",
              contain: "",
              src: require("@/assets/logos/dow-diamond.svg")
            }
          }),
          _c(
            "div",
            { staticClass: "close" },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.modalClosed()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-carousel",
            {
              ref: "carousel",
              attrs: {
                height: "100%",
                "hide-delimiters": "",
                "show-arrows": false
              },
              scopedSlots: _vm._u([
                {
                  key: "next",
                  fn: function(ref) {
                    var props = ref.props
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { variant: "elevated", color: "info" },
                          on: { click: props.onClick }
                        },
                        [_vm._v(" Next slide ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.activePage,
                callback: function($$v) {
                  _vm.activePage = $$v
                },
                expression: "activePage"
              }
            },
            _vm._l(_vm.pages, function(page, i) {
              return _c(
                "v-carousel-item",
                { key: i },
                [
                  _c("HelpModalPageComponent", {
                    attrs: {
                      title: page.title,
                      text1: page.text1,
                      bold: page.bold,
                      text2: page.text2,
                      text3: page.text3,
                      img: page.img
                    }
                  })
                ],
                1
              )
            }),
            1
          ),
          _c("div", { staticClass: "nav-buttons-container" }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activePage > 0,
                        expression: "activePage > 0"
                      }
                    ],
                    staticClass: "prev-button",
                    attrs: { depressed: "", color: "rgba(0, 132, 118, 1)" },
                    on: {
                      click: function($event) {
                        return _vm.prevPage()
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("arrow_back")])],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "next-button",
                    attrs: { depressed: "", color: "rgba(0, 132, 118, 1)" },
                    on: {
                      click: function($event) {
                        return _vm.nextPage()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.DCSLanguageService.translate(
                            _vm.nextButtonMessage
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activePage < _vm.pages.length - 1,
                            expression: "activePage < pages.length - 1"
                          }
                        ],
                        staticClass: "forward-arrow"
                      },
                      [_vm._v(" arrow_forward ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }