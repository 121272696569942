











































import { Component, Prop, Vue } from "vue-property-decorator";

import ContactButtonComponent from "./sub-components/ContactButton.vue";

import { urlReplaceSpacesAndLower } from "@/util/url-formatter.utility";

import { DCSLanguageService } from "@/services";

@Component({
  components: {
    ContactButtonComponent,
  },
})
export default class GenericHeaderComponent extends Vue {
  @Prop()
  isOnRootPath!: boolean;

  @Prop()
  showExploreCity!: boolean;

  public DCSLanguageService = new DCSLanguageService();



  public handleContactClick(): void {
    this.$root.$emit("contact-form", true);
  }

  public handleToCityClick(): void {
    if (this.$store.state.isMobile) {
      this.$router.push("/");
      this["$appInsights"].appInsights.trackEvent({
        name: `Back to Home - ${this.$store.state.city.labelText}`,
        properties: {
          page: "Product Overview Page",
          taggedItemType: "Back Button",
          taggedItemText: "Back to Home - PRODUCT OVERVIEW PAGE NAME",
        },
      });
      // eslint-disable-next-line
      (window as any).dataLayer.push({
        event: "Link Tracking",
        link: {
          linkName: `${this.$store.state.city.labelText}`, // usually link text or other identifier
          linkPosition: "Mobile - Back to Home", // location of link on page
          linkPage: "Product Overview Page", // usually `page.pageInfo.pageName` from `Page Viewed` event
        },
      });
    } else {
      this["$appInsights"].appInsights.trackEvent({
        name: `Back to ${this.$store.state.city.labelText}`,
        properties: {
          page: "Product Overview Page",
          taggedItemType: "Back Button",
          taggedItemText: "Back to SECTION HEAD",
        },
      });
      // eslint-disable-next-line
      (window as any).dataLayer.push({
        event: "Link Tracking",
        link: {
          linkName: `${this.$store.state.city.labelText}`, // usually link text or other identifier
          linkPosition: "Desktop - Back to SECTION HEAD", // location of link on page
          linkPage: "Product Overview Page", // usually `page.pageInfo.pageName` from `Page Viewed` event
        },
      });

      if (
        this.$store.state.city.navigateTo &&
        this.$store.state.city.navigateTo.id
      ) {
        const param = urlReplaceSpacesAndLower(
          `${this.$store.state.city.navigateTo.id}-${this.$store.state.city.navigateTo.identifier}`
        );
        const { innovation } = this.$route.query;
        const innovationParam = innovation ? "?innovation=true" : "";
        this.$router.push(`/city/navigator/${param}/${innovationParam}`);
      } else {
        //were coming from a direct link so don't know where to go back to
        //send them to the top level city
        this.$router.push("/city");
      }
    }
  }
}
