var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "anniversary" },
    [
      _c("v-img", {
        staticClass: "img",
        attrs: {
          "max-width": "80",
          "max-height": "80",
          src: require("@/assets/AW.png")
        }
      }),
      _c("p", [
        _vm._v(
          " We celebrate the 50th anniversary of Dow’s pioneering 4-sided silicone structural glazing, which enabled the many visually stunning glass façades seen in cities today. "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }