import { AxiosResponse } from "axios";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { http } from "../http-client";
import { serializeStrapiResponse } from "@/util/seralize.utility";

import { DCSLanguageService } from "@/services";
import { Event } from "@/types/event.interface";

export class EventService {
  private DCSLanguageService = new DCSLanguageService();
  /**
   * GET from list of Events
   */
  public getUpcomingEventList(): Observable<Event[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() => http.get(`/dcs-language/events/${languageID}`)).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((events) =>
      events.map((event) => serializeStrapiResponse(event) as Event)
      )
    );
  }
}
