var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-select", {
        staticClass: "custom",
        attrs: { items: _vm.syncedItems },
        on: {
          input: function($event) {
            return _vm.selectChange($event)
          }
        },
        model: {
          value: _vm.syncedSelected,
          callback: function($$v) {
            _vm.syncedSelected = $$v
          },
          expression: "syncedSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }