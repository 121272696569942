



























import { Vue, Component, Prop } from "vue-property-decorator";

import SubNavigatorsComponent from "@/components/city/navigators/SubNavigators.vue";
import ZoomContentComponent from "@/components/city/ZoomContent.vue";
import NavigatorsComponent from "@/components/city/navigators/Navigators.vue";

import { MetaService } from "@/services";
import { Navigator, SubNavigator } from "@/types";

@Component({
  components: {
    ZoomContentComponent,
    SubNavigatorsComponent,
    NavigatorsComponent,
  },
})
export default class ZoomedNavigatorsComponent extends Vue {
  @Prop()
  public navigateTo!: Navigator | SubNavigator;

  private metaService = new MetaService();

  mounted(): void {
    if (
      this.navigateTo.metaDescription == undefined ||
      this.navigateTo.metaTitle == undefined
    ) {
      this.metaService.updateMetaDescription(this.navigateTo.cardDescription);
      this.metaService.updateMetaTitle(this.navigateTo.labelText);
    } else {
      this.metaService.updateMetaDescription(this.navigateTo.metaDescription);
      this.metaService.updateMetaTitle(this.navigateTo.metaTitle);
    }
  }
}
