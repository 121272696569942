import { AxiosResponse } from "axios";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { http } from "../http-client";
import { ResourceLink } from "@/types";
import { serializeStrapiResponse } from "@/util/seralize.utility";

import { DCSLanguageService } from "../dcs-language.service";

export class ResourceLinkService {
  private DCSLanguageService = new DCSLanguageService();

  public getResourceLinks(): Observable<ResourceLink[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;

    return defer(() =>
      http.get(`/dcs-language/resource-links/${languageID}`)
    ).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((resourceLinks) =>
        resourceLinks.map(
          (resourceLink) =>
            serializeStrapiResponse(resourceLink) as ResourceLink
        )
      )
    );
  }
}
