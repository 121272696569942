

















import { Component, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

@Component
export default class DataPrivacyContent extends Vue {
  public DCSLanguageService = new DCSLanguageService();
}
