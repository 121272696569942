
































import { Vue, Component } from "vue-property-decorator";
import { ComponentOptions } from "vue/types/options";

import ContactEntryP1 from "@/components/contact/ContactEntryP1.vue";
import ContactEntryP2 from "@/components/contact/ContactEntryP2.vue";
import ContactEntryP3 from "@/components/contact/ContactEntryP3.vue";
import ContactEntryP4 from "@/components/contact/ContactEntryP4.vue";
import ContactEntryComplete from "@/components/contact/ContactEntryComplete.vue";

import { EloquaService } from "@/services";
import { ContactEloqua } from "@/types";

const metaInfo: ComponentOptions<Vue>['metaInfo'] = {
  title: 'Contact Our Building Science Experts',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'If you have any questions about our Building Science products or applications, please contact our Dow specialist today.',
    },
  ],
};

export const metaInfoMixin = {
  metaInfo,
};

@Component({
  mixins: [metaInfoMixin],
  components: {
    ContactEntryP1,
    ContactEntryP2,
    ContactEntryP3,
    ContactEntryP4,
    ContactEntryComplete,
  },
})
export default class CalendlyComponent extends Vue {
  public drawerOpen = false;

  public closeDrawer(): void {
    this.$root.$emit("contact-form", false);
  }

  private eloquaService = new EloquaService();

  private transitionedOpen = false;
  public currentFormPage = "ContactEntryP1";
  public formValues = {
    email: "",
    firstName: "",
    lastName: "",
    company: "",
    country: "",
    marketSegment: [],
    reason: "",
    comments: "",
    role: "",
    projectSize: "",
    budgetType: "",
    purchaseProcessStep: "",
    optIn: "",
  };

  private mounted(): void {
    this.drawerOpen = true;
  }

  public transitioned(event: TransitionEvent): void {
    if (event.propertyName === "visibility") {
      if (!this.transitionedOpen) {
        this.$root.$emit("contact-form", false);
      } else {
        this.transitionedOpen = true;
      }
    }
  }

  public navigateForm(currentFormPage: string): void {
    this.currentFormPage = currentFormPage;
  }

  public resetFormValues(): void {
    Object.keys(this.$store.state.contact).forEach((e) => {
      if (e === "marketSegment") {
        this.$store.commit("UPDATE_CONTACT_STATE", { type: e, value: [] });
      } else {
        this.$store.commit("UPDATE_CONTACT_STATE", { type: e, value: "" });
      }
    });
  }

  public async submitForm(e: {
    formValues: object;
    submit: boolean;
  }): Promise<void> {
    if (!e.formValues) {
      return;
    }

    this.formValues = {
      ...this.formValues,
      ...e.formValues,
    };

    if (e.submit) {
      const eloquaData: ContactEloqua = {
        email: this.formValues.email,
        firstName: this.formValues.firstName,
        lastName: this.formValues.lastName,
        company: this.formValues.company,
        country: this.formValues.country,
        marketSegment: this.formValues.marketSegment,
        reason: this.formValues.reason,
        comments: this.formValues.comments,
        role: this.formValues.role,
        projectSize: this.formValues.projectSize,
        budgetType: this.formValues.budgetType,
        purchaseProcessStep: this.formValues.purchaseProcessStep,
        optIn: this.formValues.optIn,
      };

      let response;
      try {
        response = await this.eloquaService.postEloquaFormData(eloquaData);
      } catch (e) {
        console.error("Could not submit to Eloqua Azure Function - \n", e);
      }
      this.resetFormValues();
    }
  }
}
