




































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

import DropdownComponent from "@/components/core/headers/sub-components/DropdownComponent.vue";

import { LocaleService, DCSLanguageService } from "@/services";
import { Locale } from "@/types/locale.interface";
import { DCSLanguage } from "@/types";

@Component({
  components: {
    DropdownComponent,
  },
  computed: {
    ...mapState(['isSideDrawerOpened']),
  },
})
export default class SideDrawerComponent extends Vue {
  private localeService = new LocaleService();
  public languageService = new DCSLanguageService();

  public selectedLocaleName = "";
  public locales!: Locale[];
  public localeNames: string[] = [];
  public localeDictionary: { name: string; translatedName: string  }[] = [];

  public selectedLanguageName = "";
  public languages!: DCSLanguage[];
  public languageNames: string[] = [];
  public languageDictionary: { name: string; translatedName: string }[] = [];

  public isSideDrawerOpened!: boolean;
  public displayLanguageDropDown = false;

  private async created(): Promise<void> {
    this.locales = await this.localeService.getLocales().toPromise();
    this.localeDictionary = this.locales.map((locale) => {
      return {
        name: locale.name,
        translatedName: this.languageService.translate(locale.name),
      };
    });
    this.localeNames = this.locales.map((locale) => 
      this.languageService.translate(locale.name)
    );

    const locale = this.localeService.getLocale();

    if (locale) {
      this.selectedLocaleName = this.languageService.translate(locale.name);
    } else {
      this.selectedLocaleName = this.languageService.translate(
        this.locales[0].name
      );
    }

    this.languages = await this.languageService.getDCSLanguages().toPromise();
    this.languageDictionary = this.languages.map((language) => {
      return {
        name: language.name,
        translatedName: this.languageService.translate(language.name),
      };
    });

    if (this.languages.length > 1) {
      this.displayLanguageDropDown = true;
    }

    this.languageNames = this.languages.map((language) => 
      this.languageService.translate(language.name)
    );
    this.selectedLanguageName = this.languageService.translate(
      this.languageService.getDCSLanguage().name
    );
  }
  
  public updateSideDrawerState(state: boolean): void {
    this.$store.commit("UPDATE_OPENSIDEDRAWER_STATE", state);
  }

  setLocale(localeName: string): void {
    const localeTranslatedName = this.localeDictionary.find(
      (l) => l["translatedName"] === localeName
    );
    const locale = this.locales.find(
      (locale) => locale.name === localeTranslatedName?.name
    );

    this.localeService.setLocale(locale as Locale);
    this.selectedLocaleName = this.languageService.translate(localeName);

    // DO NOT CHANGE TO location.reload()
    // This cannot be location.reload() because not every locale has the same content
    location.href = '/city';
  }

  setLanguage(languageName: string): void {
    const languageTranslatedName = this.languageDictionary.find(
      (l) => l["translatedName"] === languageName
    );
    const language = this.languages.find(
      (language) => language.name === languageTranslatedName?.name
    );

    this.selectedLanguageName = this.languageService.translate(language?.name ?? 'English');
    this.languageService.setDCSLanguage(language as DCSLanguage);

    //DO NOT CHANGE TO location.reload()
    //This cannot be location.reload() because for a different language the content ID will be different
    location.href = '/city';
  }
}
