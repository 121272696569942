var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrap" },
    [
      !_vm.appData
        ? [_c("v-skeleton-loader", { attrs: { type: "card" } })]
        : _vm._e(),
      _vm.appData
        ? [
            _c(
              "div",
              {
                staticClass: "content-text hero2",
                attrs: { id: "" + _vm.appData.identifier }
              },
              [
                _c("h3", { staticClass: "top" }, [
                  _vm._v(" " + _vm._s(_vm.appData.title) + " ")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.appData.description))])
              ]
            ),
            _c("div", { staticClass: "content-image" }, [
              _c("img", { attrs: { src: _vm.getMediaPath(_vm.appData.image) } })
            ]),
            _c(
              "div",
              { staticClass: "content-text" },
              [
                _c("v-icon", [_vm._v(_vm._s(_vm.appData.listIcon))]),
                _c("p", [_vm._v(_vm._s(_vm.appData.title) + " Applications")]),
                _c(
                  "div",
                  { staticClass: "app-list" },
                  _vm._l(_vm.appData.items, function(item, index) {
                    return _c(
                      "router-link",
                      {
                        key: index,
                        staticClass: "action-link",
                        attrs: { to: "/" + item.link },
                        on: {
                          click: function($event) {
                            return _vm.customTracker(item.label + " - Button")
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(item.label)),
                        _c("v-icon", [_vm._v("mdi-chevron-right")])
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }