import axios, { AxiosRequestConfig } from 'axios';

import { blobDataPath } from '@/util/blob-data-path.utility';
import { Locale } from '@/types';

const http = axios.create({
  baseURL: blobDataPath,
  headers: {
    "Content-Type": "application/json"
  }
});

let locale;
//cannot use localeService here because this runs before vue init
try {
  locale = JSON.parse(localStorage.getItem('locale') as string) as Locale;
} catch(e) {
  //ignore
}

if (locale) {
  http.interceptors.request.use((config): AxiosRequestConfig => {
    config.params = config.params || {};
    config.params['_locale'] = locale.code;
    return config;
  })
}

export {
  http
};