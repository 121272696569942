











import { Vue, Component, Prop } from "vue-property-decorator";
import { take, takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";

import BuildingNavigatorComponent from "@/components/city/navigators/BuildingNavigator.vue";

import { CityNavigatorService, ProductService } from "@/services";
import { ProductDetail, SubNavigator } from "@/types";
import { createProductUrl, createListUrl } from '@/util';

@Component({
  components: {
    BuildingNavigatorComponent,
  },
})
export default class SubNavigatorsComponent extends Vue {
  @Prop()
  public subNavigators!: SubNavigator[];

  private xsubNavigatorList: SubNavigator[] | null = null;
  public get subNavigatorList(): SubNavigator[] | null {
    return this.xsubNavigatorList;
  }
  public set subNavigatorList(list: SubNavigator[] | null) {
    this.xsubNavigatorList = list;
  }

  public subNavigatorSelected(subNavigator: SubNavigator | null): void {
    if (subNavigator == null) {
      console.error('SubNavigator is null, this should NOT happen!');
      console.trace();
      return;
    }

    this["$appInsights"].appInsights.trackEvent({
      name: `${subNavigator.labelText}`,
    });
    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Link Tracking",
      link: {
        linkName: `${subNavigator.labelText}`, // usually link text or other identifier
        linkPosition: "Subcategory Button", // location of link on page
        linkPage: "Subcategory Navigator", // usually `page.pageInfo.pageName` from `Page Viewed` event
      },
    });
    if (subNavigator.productDetail && !subNavigator.template) {
      this.productService
        .getProductById(subNavigator.productDetail.id)
        .pipe(take(1))
        .subscribe((product: ProductDetail) => {
          if (product) {
            this.$router.push({
              path: `/${createProductUrl(product.heading)}`,
            });
          }
        });
    } else {
      this.$router.push({
        path: `/${createListUrl(subNavigator.id, subNavigator.identifier)}`,
      });
    }
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private cityNavigatorService = new CityNavigatorService();
  private productService = new ProductService();

  private getSubNavigatorList(): void {
    const subcategoryNavigatorIdList = this.subNavigators.map(
      (subNavigator) => subNavigator.id
    );

    this.cityNavigatorService
      .getSubNavigatorListByIds(subcategoryNavigatorIdList)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subNavigators) => {
        const hydratedSubnavigators = subNavigators.map((subNavigator) => {
          if (subNavigator.productDetail) {
            subNavigator.innovationCount = subNavigator.productDetail.innovation
              ? 1
              : 0;
          } else if (
            subNavigator.template &&
            Object.keys(subNavigator.template).length > 0
          ) {
            let innovationCount = 0;
            subNavigator.template.cards.forEach((card) => {
              if (card.productDetail && card.productDetail.innovation) {
                innovationCount += 1;
              }
            });
            subNavigator.innovationCount = innovationCount;
          } else {
            console.error(
              `This subnavigator does not have a product or a template! One or the other is required! Sub-Navigatior with the id of ${subNavigator.id} needs to be fixed in the CMS`
            );
          }

          return subNavigator;
        });

        this.subNavigatorList = hydratedSubnavigators;
      });
  }

  private created(): void {
    this.getSubNavigatorList();
  }

  private destroyed(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
