












































































































































import { Component, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

@Component
export default class MobileNavComponent extends Vue {
  public year = new Date().getFullYear();
  public languageService = new DCSLanguageService();

  public customTracker(desc): void {
    this["$appInsights"].appInsights.trackEvent({ name: `${desc}` });
    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Link Tracking",
      link: {
        linkName: `${desc}`, // usually link text or other identifier
        linkPosition: "footer", // location of link on page
        // eslint-disable-next-line
        linkPage: (window as any).page.pageInfo.pageName, // usually `page.pageInfo.pageName` from `Page Viewed` event
      },
    });
  }

  public firstContent = [
    {
      text: "Innovations",
      icon: "emoji_objects",
      path: "/list/innovations",
    },
    {
      text: this.languageService.translate("WebinarsAndVideos"),
      icon: "play_circle_filled",
      path: "/webinars",
      desc: "menu - webinars",
    },
    {
      text: "Connect with a Specialist",
      icon: "forum",
      path: "/contact",
      desc: "menu - connect",
    },
  ];

  public secondContent = [
    {
      text: this.languageService.translate("UpcomingEvents"),
      icon: "event",
      path: "/events",
      desc: "menu - upcoming events",
    },
    {
      text: "News",
      icon: "newspaper",
      path: "/events",
      desc: "menu - news",
    },
    {
      text: this.languageService.translate("NewProducts"),
      icon: "new_releases",
      path: "/new-products",
      desc: "menu - new products",
    },
  ];

  public thirdContent = [
    {
      text: "Building Specification",
      icon: "open_in_new",
      path:
        "https://www.dow.com/en-us/market/mkt-building-construction/construction-on-line.html",
      desc: "menu - Building Services - Dow.com",
    },
    {
      text: "Specifications Support",
      icon: "open_in_new",
      path:
        "https://www.dow.com/en-us/market/mkt-building-construction/submittal-resources-europe.html",
      desc: "menu - Specifications - Dow.com",
    },
    {
      text: "Quality Bond",
      icon: "open_in_new",
      path:
        "https://www.dow.com/en-us/market/mkt-building-construction/quality-bond.html",
      desc: "menu - Quality Bond - Dow.com",
    },
  ];

  private xdrawer = false;

  public get drawer(): boolean {
    return this.xdrawer;
  }
  public set drawer(isOpen: boolean) {
    this.xdrawer = isOpen;
  }

  mounted(): void {
    this.$root.$on("drawer", (isOpen) => {
      this["$appInsights"].appInsights.trackEvent({ name: "menu" });
      // eslint-disable-next-line
      (window as any).dataLayer.push({
        event: "Link Tracking",
        link: {
          linkName: "menu", // usually link text or other identifier
          linkPosition: "Header Link", // location of link on page
          // eslint-disable-next-line
          linkPage: "Landing Page", // usually `page.pageInfo.pageName` from `Page Viewed` event
        },
      });
      this.drawer = isOpen;
    });
  }
}
