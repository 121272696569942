var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "city-zoomer-wrapper" }, [
    _c(
      "video",
      {
        ref: "cityVideo",
        staticClass: "video",
        attrs: { crossorigin: "anonymous", preload: "none" }
      },
      [
        _c("source", { attrs: { src: _vm.getZoomVideo } }),
        _c("p", [
          _vm._v("Your user agent does not support the HTML5 Video element.")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }