var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { absolute: "", temporary: "", width: "300px", height: "100vh" },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "logo-container" },
                [
                  _c("v-img", {
                    attrs: {
                      "max-width": "110px",
                      src: require("@/assets/logos/dow-diamond.svg")
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "append",
          fn: function() {
            return [
              _c(
                "a",
                {
                  staticClass: "visit-dow",
                  attrs: {
                    href:
                      "https://www.dow.com/en-us/market/mkt-building-construction.html",
                    target: "_blank"
                  },
                  on: {
                    click: function($event) {
                      return _vm.customTracker("menu - visit Dow.com")
                    }
                  }
                },
                [_vm._v(" Visit "), _c("b", [_vm._v("Dow.com")])]
              ),
              _c("v-divider", { staticClass: "footer-divider" }),
              _c("div", { staticClass: "link-content" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.dow.com/en-us/contact-us.html",
                      target: "_blank"
                    },
                    on: {
                      click: function($event) {
                        return _vm.customTracker("menu - contact us")
                      }
                    }
                  },
                  [_vm._v("Contact Us")]
                ),
                _vm._v(" | "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://legal.dow.com/en-us/privacy-statement",
                      target: "_blank"
                    },
                    on: {
                      click: function($event) {
                        return _vm.customTracker("menu - privacy statement")
                      }
                    }
                  },
                  [_vm._v(" Privacy Statement ")]
                ),
                _vm._v(" | "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://legal.dow.com/en-us/terms-of-use",
                      target: "_blank"
                    },
                    on: {
                      click: function($event) {
                        return _vm.customTracker("menu - terms of use")
                      }
                    }
                  },
                  [_vm._v("Terms of Use")]
                )
              ]),
              _c("div", { staticClass: "copyright" }, [
                _vm._v(
                  " Copyright © Dow (1995-" +
                    _vm._s(_vm.year) +
                    "). All Rights Reserved."
                ),
                _c("br"),
                _vm._v(" ®™ Trademark of Dow or an affiliated company of Dow ")
              ])
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            _vm._l(_vm.firstContent, function(item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  attrs: { to: item.path, exact: "" },
                  on: {
                    click: function($event) {
                      return _vm.customTracker(item.desc)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s(item.icon) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(item.text) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            _vm._l(_vm.secondContent, function(item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  attrs: { href: item.path, exact: "" },
                  on: {
                    click: function($event) {
                      return _vm.customTracker(item.desc)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s(item.icon) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(item.text) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            _vm._l(_vm.thirdContent, function(item, i) {
              return _c(
                "v-list-item",
                { key: i, attrs: { href: item.path, target: "_blank" } },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s(item.icon) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(item.text) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("v-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }