










































































import { Component, Vue } from "vue-property-decorator";

import ContactHeaderBarComponent from "@/components/contact/sub-components/ContactHeaderBar.vue";

import { DCSLanguageService } from "@/services";

@Component({
  components: {
    ContactHeaderBarComponent,
  },
})
export default class ContactEntryP2 extends Vue {
  public DCSLanguageService = new DCSLanguageService();

  // todo: Once translations are given, add these to the DCSLanguage Service with
  // a getter to grab correct translation
  public roles = [
    "Architect / Designer",
    "Consultant / Materials Specialist",
    "Facilities Manager / Building Owner",
    "OEM Coordinator",
    "Project Manager / Contractor",
    "Purchasing Manager",
    "Quality Engineer / Testing",
    "R&D / Technical Manager",
    "Specification Writer",
    "Student / Professor",
    "Other",
  ];

  public projectSizes = [
    "Large (>20,000 m²)",
    "Medium (5,000-20,000 m²)",
    "Small (<5,000 m²)",
  ];

  public budgetTypes = [
    "Large (>$100,000)",
    "Medium ($25,000-100,000)",
    "Small (<$25,000)",
    "Small - Green Building Certificate",
    "Small - KOL Driven Project",
    "Small - Iconic Project",
  ];

  public purchaseProcessSteps = [
    "Construction Phase",
    "Bid Phase",
    "Design Phase",
    "Research Phase",
    "Other",
  ];

  public addToContactFormValues(): void {
    const formValues = this.$store.getters.getP3FormValues;

    this.$emit("submit-form", { formValues: formValues, submit: false });
    this.$emit("navigate-form", "ContactEntryP4");
  }

  public sendToStore(e, type): void {
    this.$store.commit("UPDATE_CONTACT_STATE", { type, value: e });
  }
}
