
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Navigator } from "@/types";
import { blobDataPath } from "@/util/blob-data-path.utility";
import { LocaleService } from "@/services";
import { MutationPayload } from "vuex";
import { cacheVideo } from "@/util/preload-video.utility";

@Component({
  components: {},
  computed: {
    getZoomVideo(): string {
      if (!this.$props.navigateTo.id) {
        return "";
      }
      if (this.$data.isZoomingIn) {
        return blobDataPath + this.$props.navigateTo.zoomVideo.url;
      } else {
        return blobDataPath + this.$props.navigateTo.reverseZoomVideo.url;
      }
    }
  }
})
export default class CityZoomerComponent extends Vue {
  @Prop()
  public navigateTo!: Navigator;

  @Watch('navigateTo', { immediate: true })
  public onNavigateToChange(nav: Navigator): void {
    if (!nav.id) {
      return;
    }

    // Runtime cache / preload zoom out video
    cacheVideo(nav.reverseZoomVideo.url);
  }

  private localeService = new LocaleService();

  private videoPlayer!: HTMLVideoElement;
  getZoomVideo!: string;

  isZoomingIn = false;
  

  private unsubToMutations!: ()=> void;

  private cityHeaderBackTriggerHandler(): void {
    this.zoomInOrOut(false);
  }

  private mounted(): void {
    this.videoPlayer = this.$refs.cityVideo as HTMLVideoElement;

    this.$root.$on('city-header-back-triggered', this.cityHeaderBackTriggerHandler);

    this.unsubToMutations = this.$store.subscribe((mutation: MutationPayload, state) => {
        if (
          mutation.type === 'CITY_NAVIGATE_TO'
        ) {
          this.zoomInOrOut(true);
        }
      });
  }

  private beforeDestroy(): void {
    this.unsubToMutations();
    this.$root.$off('city-header-back-triggered', this.cityHeaderBackTriggerHandler);
    this.videoPlayer.removeEventListener('playing', this.videoPlayingHandler);
    this.videoPlayer.removeEventListener('ended', this.videoEndedHandler);
  }

  
  private videoPlayingHandler(): void {
    this.videoPlayer.removeEventListener('playing', this.videoPlayingHandler);
    this.$store.commit('CITY_IS_ZOOMING', true);
    this.hideShowIntercom();
    const innovationsOn = this.$route.query.innovation ? true : false;
    //pretty hacky to pass router into the store, but this is the only way currently to keep the vue router aligned with the its correct navigator
    this.$store.dispatch('updateZoomingStateChange', { innovationsOn, router$: this.$router, isZoomingIn: this.isZoomingIn });
  }

  private videoEndedHandler(): void {
    this.videoPlayer.removeEventListener('ended', this.videoEndedHandler);
    this.$store.commit('CITY_IS_ZOOMING', false);
    this.hideShowIntercom();
    this.$store.commit('CITY_WILL_ZOOM', false);
    this.$store.commit('CITY_IS_ZOOMED', this.isZoomingIn);
  }

  private playZoomSegment(): void {
    this.videoPlayer.load();

    this.videoPlayer.muted = true;
    this.videoPlayer.loop  = false;

    this.videoPlayer.addEventListener(
      'playing',
      this.videoPlayingHandler,
      false
    );

    this.videoPlayer.addEventListener(
      'ended',
      this.videoEndedHandler,
      false
    );

    this.videoPlayer.play();
  }

  private zoomInOrOut(isZoomingIn: boolean): void {
    this.isZoomingIn = isZoomingIn;
    this.playZoomSegment();
  }

  private hideShowIntercom(): void {
    const { isZooming } = this.$store.state.city;

    const locale = this.localeService.getLocale();
    if ((String(process.env.VUE_APP_ENABLE_INTERCOM) == 'true') && (locale === null || (locale && locale.code === 'en'))) {
      // for some reason when intercom is never opened, it is tagged as intercomEl, whereas once opened it changes its HTML structure.
      // I need to make sure to grab that element also once it has opened. If intercomEl is null, that means it has been opened, and will
      // now only ever be intercomEl2
      const intercomEl = document.querySelector<HTMLElement>(".intercom-lightweight-app");
      const intercomEl2 = document.querySelector<HTMLElement>(".intercom-launcher-frame");

      const intercomElement = intercomEl ? intercomEl : (intercomEl2 ? intercomEl2 : null);
      
      if (intercomElement) {
        if (isZooming) {
          intercomElement.style.display = 'none';
        } else {
          intercomElement.style.display = 'block';
        }
      }
    }
  }
}
