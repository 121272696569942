import { ProductDetail } from "@/types";

export function getExploreMoreLink(product: ProductDetail | null): string {
  if (product && product.buttonLink) {
    const exploreMoreLink = new URL(product.buttonLink);

    if (product.buttonCid) {
      exploreMoreLink.searchParams.append('cid', product.buttonCid);
    }
    

    return exploreMoreLink.toString();
  }

  return '';
}