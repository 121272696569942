var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c("div", { staticClass: "text-container" }, [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _c("p", { staticClass: "text1" }, [
        _vm._v(" " + _vm._s(_vm.text1) + " ")
      ]),
      _c("p", { staticClass: "text2" }, [
        _c("b", [_vm._v(_vm._s(_vm.bold))]),
        _vm._v(" " + _vm._s(_vm.text2) + " ")
      ]),
      _c("p", { staticClass: "text3" }, [_vm._v(" " + _vm._s(_vm.text3) + " ")])
    ]),
    _c("div", { staticClass: "img-flex-box" }, [
      _c(
        "div",
        { staticClass: "img-container" },
        [_c("v-img", { attrs: { src: _vm.imageSrc } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }