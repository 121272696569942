import { Locale } from "@/types";
import { serializeStrapiResponse } from "@/util/seralize.utility";
import { AxiosResponse } from "axios";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { http } from "./http-client";

export class LocaleService {
  public getLocales(): Observable<Locale[]> {
    const { NODE_ENV, VUE_APP_NODE_ENV } = process.env;
    const hideUnfinishedLocales =
      NODE_ENV === "production" || VUE_APP_NODE_ENV === "production";
    return defer(() => http.get("/i18n/locales")).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((locales) =>
        locales.map((locale) => serializeStrapiResponse(locale) as Locale)
      ),
      map((locales) =>
        hideUnfinishedLocales ? this.temporarilyHideLocales(locales) : locales
      ),
      map((locales) =>
        locales.map((locale) => {
          const modifedLocale = { ...locale };
          modifedLocale.name = modifedLocale.name.split("|")[0];
          return modifedLocale;
        })
      )
    );
  }

  //this method is temporary to allow us to hide unfinished locales
  private temporarilyHideLocales(locales: Locale[]): Locale[] {
    const readyLocaleCodes = ["en", "en-US", "en-AU", "en-BZ", "en-IN", "en-SA"];
    const readyLocales = locales.filter((locale) =>
      readyLocaleCodes.includes(locale.code)
    );
    return readyLocales;
  }

  public async setDefaultLocale(): Promise<Locale> {
    const locales = await this.getLocales().toPromise();
    return locales[0];
  }

  public setLocale(locale: Locale): void {
    localStorage.setItem("locale", JSON.stringify(locale));
    // When we change locale the language needs to be reset before reload
    localStorage.removeItem("DCSLanguage");
  }

  private initializeLocale(): Locale {
    const defaultLocale = {name: "Europe ", code: "en", id: 1, isDefault: true} as Locale
    this.setLocale(defaultLocale)
    return defaultLocale
  }

  public getLocale(): Locale {
    try {
      const locale = JSON.parse(
        localStorage.getItem("locale") as string
      ) as Locale;
      if (locale === null) {
        return this.initializeLocale();
      }
      return locale;
    } catch (e) {
      throw new Error("Unable to get locale");
    }
  }
}
