











import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ReplaySubject } from "rxjs";

import BuildingNavigatorComponent from "@/components/city/navigators/BuildingNavigator.vue";

import { urlReplaceSpacesAndLower } from "@/util/url-formatter.utility";
import { cacheVideo } from "@/util/preload-video.utility";

import { CityNavigatorService, MetaService } from "@/services";
import { Navigator } from "@/types";

@Component({
  components: {
    BuildingNavigatorComponent,
  },
})
export default class NavigatorsComponent extends Vue {
  @Prop()
  public navigators!: Navigator[];

  private xnavigatorList: Navigator[] | null[] = [];
  private metaService = new MetaService();
  public get navigatorList(): Navigator[] | null[] {
    return this.xnavigatorList;
  }
  public set navigatorList(list: Navigator[] | null[]) {
    this.xnavigatorList = list;
  }

  @Watch("navigators", { immediate: true })
  public onNavigatorsChange(navigators: Navigator[]): void {
    this.getNavigatorList().then(() => {

      // Runtime cache / preload zoom videos
      this.navigatorList.forEach((nav) => {
        if (nav && nav.zoomVideo.url) {
          cacheVideo(nav?.zoomVideo.url);
        }
      });
    });
  }

  public navigatorSelected(navigateTo: Navigator): void {
    this["$appInsights"].appInsights.trackEvent({
      name: `${navigateTo.labelText} Button`,
      properties: {
        page: "City View",
        taggedItemType: "Back Button",
        taggedItemText: "SECTION NAME Button",
      },
    });
    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Link Tracking",
      link: {
        linkName: `${navigateTo.labelText} Button`, // usually link text or other identifier
        linkPosition: "Back Button", // location of link on page
        linkPage: "City View", // usually `page.pageInfo.pageName` from `Page Viewed` event
      },
    });

    const param = urlReplaceSpacesAndLower(
      `${navigateTo.id}-${navigateTo.identifier}`
    );
    const { innovation } = this.$route.query;
    const innovationParam = innovation ? "?innovation=true" : "";
    this.$router.push(`/city/navigator/${param}/${innovationParam}`);
    this.$store.dispatch("navigateForward", navigateTo);
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private cityNavigatorService = new CityNavigatorService();

  private async getNavigatorList(): Promise<void> {
    if (this.navigators && this.navigators.length > 0) {
      const navigatorIds = this.navigators.map((navigator) => navigator.id);
      const navigators = await this.cityNavigatorService
        .getNavigatorListByIds(navigatorIds)
        .toPromise();
      this.navigatorList = navigators;
    } else if (this.$route.params.navigator) {
      const navigatorId = Number(this.$route.params.navigator.split("-")[0]);
      if (isNaN(navigatorId)) {
        console.error(
          "Unable to parse navigator id from url, so we are unable to retrieve navigator or subnavigator buttons"
        );
      } else {
        try {
          const navigator = await this.cityNavigatorService
            .getNavigatorById(navigatorId)
            .toPromise();

          if (navigator.parentNavigator) {
            try {
              //need to attach parent navigators children for backward navigation
              const parentNavigator = await this.cityNavigatorService
                .getNavigatorById(navigator.parentNavigator.id)
                .toPromise();
              navigator.parentNavigator = parentNavigator;
            } catch (e) {
              //ignore for now
              console.error("Error when trying to hydrate parent navigator", e);
            }
          }
          this.$store.dispatch("setCityStateFromDirectLink", navigator);
        } catch (e) {
          //this is most likely edge case where the user might have a url that is for another locale
          //if this case is hit just send them back to the city
          window.location.href = "/city";
        }
      }
    } else {
      //this is either an issue, or we are at the top city level without any history
      const categoryListData = await this.cityNavigatorService
        .getNavigatorsWithInnovations()
        .toPromise();
      const parentNavigators =
        categoryListData.filter((navigator) => !navigator.parentNavigator) ||
        {};
      this.navigatorList = parentNavigators;
    }
  }

  private created(): void {
    //this will only update meta data if on original city path
    if (window.location.pathname === "/city/") {
      this.metaService.updateMetaDescription('From building façade to urban infrastructure, Dow has high performance materials for variety of building designs for cities. Learn more today.');
      this.metaService.updateMetaTitle('City Building Science & Construction Materials');
    }
  }
  private destroyed(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
