var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-content-container" }, [
    _c(
      "div",
      { staticClass: "form-content-inner-container" },
      [
        _c("img", { attrs: { src: require("@/assets/user_connect.png") } }),
        _c("h1", [
          _vm._v(_vm._s(_vm.DCSLanguageService.translate("ConnectTitle")))
        ]),
        _c("p", [
          _vm._v(_vm._s(_vm.DCSLanguageService.translate("ConnectEmail")))
        ]),
        _c("v-text-field", {
          attrs: {
            name: "email",
            placeholder: _vm.DCSLanguageService.translate("WorkEmail"),
            rules: _vm.emailRules,
            outlined: "",
            filled: "",
            required: "",
            value: this.$store.state.contact.email
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event)
            },
            submit: function($event) {
              $event.preventDefault()
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              this.$store.getters.isEmailInvalid ? null : _vm.routeToContactP2()
            }
          }
        }),
        _c(
          "div",
          { staticClass: "actions-container" },
          [
            _c(
              "v-btn",
              {
                staticClass: "white--text green elevation-0",
                attrs: { disabled: this.$store.getters.isEmailInvalid },
                on: {
                  click: function($event) {
                    return _vm.routeToContactP2()
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.DCSLanguageService.translate("Next")) + " "
                )
              ]
            )
          ],
          1
        ),
        _c("DataPrivacyContent")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }