












import { Component, Vue } from "vue-property-decorator";

@Component
export default class ContactButtonComponent extends Vue {
  public handleContactClick(): void {
    this.$root.$emit('contact-form', true);
  }
}
