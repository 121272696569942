var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-page-2-container" }, [
    _c(
      "div",
      [
        _c("ContactHeaderBarComponent", {
          on: {
            "navigate-back": function($event) {
              return _vm.$emit("navigate-form", "ContactEntryP1")
            }
          }
        }),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", sm: "6" } },
              [
                _c("v-text-field", {
                  staticClass: "input-height",
                  attrs: {
                    label: _vm.DCSLanguageService.translate("FirstName") + "*",
                    filled: "",
                    "single-line": "",
                    outlined: "",
                    value: this.$store.state.contact.firstName
                  },
                  on: {
                    input: function($event) {
                      return _vm.sendToStore($event, "firstName")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", sm: "6" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: _vm.DCSLanguageService.translate("LastName") + "*",
                    filled: "",
                    "single-line": "",
                    outlined: "",
                    value: this.$store.state.contact.lastName
                  },
                  on: {
                    input: function($event) {
                      return _vm.sendToStore($event, "lastName")
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", sm: "6" } },
              [
                _c("v-text-field", {
                  attrs: {
                    label: _vm.DCSLanguageService.translate("Company") + "*",
                    filled: "",
                    "single-line": "",
                    outlined: "",
                    value: this.$store.state.contact.company
                  },
                  on: {
                    input: function($event) {
                      return _vm.sendToStore($event, "company")
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", sm: "6" } },
              [
                _c("v-combobox", {
                  attrs: {
                    items: _vm.countriesList,
                    label: _vm.DCSLanguageService.translate("Country") + "*",
                    filled: "",
                    "single-line": "",
                    outlined: "",
                    value: this.$store.state.contact.country
                  },
                  on: {
                    input: function($event) {
                      return _vm.sendToStore($event, "country")
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("v-divider"),
        _c("p", { staticClass: "input-label" }, [
          _vm._v(
            " " + _vm._s(_vm.DCSLanguageService.translate("WhatMarket")) + " "
          )
        ]),
        _c("v-combobox", {
          attrs: {
            items: _vm.marketSegments,
            placeholder: _vm.DCSLanguageService.translate("SelectOptions"),
            filled: "",
            outlined: "",
            multiple: "",
            value: this.$store.state.contact.marketSegment
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event, "marketSegment")
            }
          }
        }),
        _c("p", { staticClass: "input-label" }, [
          _vm._v(
            " " + _vm._s(_vm.DCSLanguageService.translate("WhatReason")) + " "
          )
        ]),
        _c("v-combobox", {
          attrs: {
            items: _vm.reasons,
            placeholder: _vm.DCSLanguageService.translate("SelectAnOption"),
            filled: "",
            outlined: "",
            value: this.$store.state.contact.reason
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event, "reason")
            }
          }
        }),
        _c("p", { staticClass: "input-label" }, [
          _vm._v(
            " " + _vm._s(_vm.DCSLanguageService.translate("AnyComments")) + " "
          )
        ]),
        _c("v-textarea", {
          attrs: {
            rows: "4",
            placeholder: _vm.DCSLanguageService.translate("Comment"),
            filled: "",
            outlined: "",
            value: this.$store.state.contact.comment
          },
          on: {
            input: function($event) {
              return _vm.sendToStore($event, "comment")
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "actions-container" },
      [
        _c("v-divider"),
        _c(
          "v-btn",
          {
            staticClass: "text-none color--white v-btn--outlined elevation-0",
            attrs: {
              rounded: "",
              disabled: !this.$store.getters.isContactFormValid
            },
            on: {
              click: function($event) {
                return _vm.submitP2FormValues(false)
              }
            }
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.DCSLanguageService.translate("NeedSpecificProject")
                ) +
                " "
            )
          ]
        ),
        _c(
          "v-btn",
          {
            staticClass: "white--text green elevation-0",
            attrs: {
              rounded: "",
              disabled: !this.$store.getters.isContactFormValid
            },
            on: {
              click: function($event) {
                return _vm.submitP2FormValues(true)
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.DCSLanguageService.translate("Next")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }