var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subcategory-navigators-wrapper" },
    _vm._l(_vm.subNavigatorList, function(subNavigator) {
      return _c("BuildingNavigatorComponent", {
        key: subNavigator.id,
        attrs: { navigator: subNavigator },
        on: {
          click: function($event) {
            return _vm.subNavigatorSelected(subNavigator)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }