





















import { Component, Vue } from "vue-property-decorator";

import DataPrivacyContent from "@/components/contact/DataPrivacyContent.vue";

import { DCSLanguageService } from "@/services";

@Component({
  components: {
    DataPrivacyContent,
  },
})
export default class ContactEntryComplete extends Vue {
  public DCSLanguageService = new DCSLanguageService();
  public closeDrawer(): void {
    this.$root.$emit("contact-form", false);
  }
}
