













































































import { Component, Vue } from "vue-property-decorator";

import HeaderLinkMenuComponent from "./sub-components/HeaderLinkMenu.vue";
import ContactButtonComponent from "./sub-components/ContactButton.vue";

import { DCSLanguageService } from "@/services";

@Component({
  components: {
    ContactButtonComponent,
    HeaderLinkMenuComponent
  },
})
export default class CityHeaderComponent extends Vue {
  public DCSLanguageService = new DCSLanguageService();

  public customTracker(desc): void {
    this["$appInsights"].appInsights.trackEvent({ name: `${desc}` });
    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Link Tracking",
      link: {
        linkName: `${desc}`, // usually link text or other identifier
        linkPosition: "Innovations Button", // location of link on page
        // eslint-disable-next-line
        linkPage: "Landing Page", // usually `page.pageInfo.pageName` from `Page Viewed` event
      },
    });
  }

  public get innovationsSelected(): boolean {
    return this.$route.query.innovation ? true : false;
  }
  public set innovationsSelected(selected: boolean) {
    const { path } = this.$route;
    const route = selected ? { path, query: { innovation: "true" } } : { path };
    this.$router.push(route);
  }

  public get isNavigatorRoute(): boolean {
    return this.$route.params.navigator ? true : false;
  }

  public handleContactClick(): void {
    this.$root.$emit("contact-form", true);
  }

  public handleSeeAllInnovationsClick(): void {
    this.customTracker("See All Innovations - Button");
    this.$router.push("/list/innovations");
  }

  public handleBackToCityClick(): void {
    this["$appInsights"].appInsights.trackEvent({
      name: `Back to City - ${this.$store.state.city.labelText}`,
      properties: {
        page: "Building View",
        taggedItemType: "Back Button",
        taggedItemText: "Back to City - PRODUCT NAME",
      },
    });
    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Link Tracking",
      link: {
        linkName: `${this.$store.state.city.labelText}`, // usually link text or other identifier
        linkPosition: "Back to City Button", // location of link on page
        linkPage: "Building View", // usually `page.pageInfo.pageName` from `Page Viewed` event
      },
    });

    this.$root.$emit("city-header-back-triggered");
  }
}
