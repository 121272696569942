var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "privacy-container" }, [
    _c("h5", [
      _vm._v(" " + _vm._s(_vm.DCSLanguageService.translate("DataPandP")) + " ")
    ]),
    _c("p", [
      _vm._v(
        " " +
          _vm._s(_vm.DCSLanguageService.translate("DataPrivacyContent")) +
          " "
      )
    ]),
    _c("p", [
      _vm._v(
        " " + _vm._s(_vm.DCSLanguageService.translate("DataPrivacyInfo")) + " "
      ),
      _c(
        "a",
        {
          attrs: { href: "https://legal.dow.com/en-us/privacy-statement.html" }
        },
        [_vm._v(_vm._s(_vm.DCSLanguageService.translate("PrivacyPolicy")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }