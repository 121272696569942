export interface DCSLanguage {
  identifier: string;
  name: string;
}

// From Strapi DCS_Language Collection
export enum DCSLanguageIdentifiers {
  europeEnglish = "Europe - English",

  northAmericaEnglish = "North America - English",

  latinAmericaEnglish = "Latin America - English",
  latinAmericaSpanish = "Latin America - Spanish",
  latinAmericaPortuguese = "Latin America - Portuguese",

  asiaEnglish = "Asia - English",

  indiaEnglish = "India - English",

  middleEastEnglish = "Middle East - English",
}
