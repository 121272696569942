import { render, staticRenderFns } from "./ContactEntryComplete.vue?vue&type=template&id=10fb156c&scoped=true"
import script from "./ContactEntryComplete.vue?vue&type=script&lang=ts"
export * from "./ContactEntryComplete.vue?vue&type=script&lang=ts"
import style0 from "./ContactEntryComplete.vue?vue&type=style&index=0&id=10fb156c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fb156c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/app/b-i-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10fb156c')) {
      api.createRecord('10fb156c', component.options)
    } else {
      api.reload('10fb156c', component.options)
    }
    module.hot.accept("./ContactEntryComplete.vue?vue&type=template&id=10fb156c&scoped=true", function () {
      api.rerender('10fb156c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/contact/ContactEntryComplete.vue"
export default component.exports