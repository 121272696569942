import { AxiosResponse } from "axios";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { http } from "../http-client";
import { serializeStrapiResponse } from "@/util/seralize.utility";

import { DCSLanguageService } from "@/services";
import { NewsArticle } from "@/types/news-article.interface";

export class NewsArticleService {
  private DCSLanguageService = new DCSLanguageService();
  /**
   * GET list of News from strapi
   */
  public getNewsArticles(): Observable<NewsArticle[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() => http.get(`/dcs-language/news-articles/${languageID}`)
    ).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((newsArticles) =>
        newsArticles.map(
          (newsArticle) => 
            serializeStrapiResponse(newsArticle) as NewsArticle)
      )
    );
  }
}
