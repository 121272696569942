



































import { Component, Vue } from "vue-property-decorator";

import { DCSLanguageService, ResourceLinkService } from "@/services";
import { ResourceLink } from "@/types";

@Component({})
export default class MenuComponent extends Vue {
  public resourceLinkService = new ResourceLinkService();
  public resourceLinks: ResourceLink[] = [];

  public DCSLanguageService = new DCSLanguageService();

  async created(): Promise<void> {
    this.resourceLinks = await this.resourceLinkService
      .getResourceLinks()
      .toPromise();
  }

  public helpClicked(): void {
    this.$store.commit("UPDATE_OPENMODAL_STATE", true);
  }
}
