var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "popup-wrapper " + _vm.customClass },
    [
      _c("v-img", {
        staticClass: "img",
        attrs: {
          "max-width": "80",
          "max-height": "80",
          src: require("@/assets/AW.png")
        }
      }),
      _c("p", [
        _vm._v(
          " " + _vm._s(_vm.DCSLanguageService.translate("WeCelebrate")) + " "
        )
      ]),
      _c(
        "v-icon",
        {
          staticClass: "close",
          on: {
            click: function($event) {
              return _vm.closePopup()
            }
          }
        },
        [_vm._v(" close ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }