




















import { Vue, Component, Prop } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

@Component
export default class AnniversaryPopupComponent extends Vue {
  @Prop()
  public customClass!: string;

  public DCSLanguageService = new DCSLanguageService();

  public closePopup(): void {
    this.$store.commit("UPDATE_AWPOPUP_STATE", false);
  }
}
