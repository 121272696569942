





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ZoomContentComponent extends Vue {
  @Prop()
  public heading!: string;

  @Prop()
  public description!: string;

  @Prop()
  public leftAlign!: boolean;
}
