/* eslint-disable */

import Vue from "vue";
import AppComponent from "./App.vue";
import router from "./router";
import VueAppInsights from "vue-application-insights";
import VueIntercom from "vue-intercom";

import "@/scss/main.scss";
import vuetify from "./plugins/vuetify";

import store from "./store";
import './class-component-hooks'

Vue.config.productionTip = false;

// Checks if Cookie consent dialog is still present
function hasProvidedConsent(): boolean {
  // Only works in production for some reason (must be a domain-managed setting on OneTrust config side with Dow peeps)
  // Still wait for OneTrust to exist on Window for group checks
  if ((window as any).OneTrust) {
    if (window.location.href.includes('dow.com')) {
      return (window as any).OneTrust.IsAlertBoxClosedAndValid();
    } else {
      return true;
    }
  } else {
    return false;
  }
}

let isApprovedPerformanceCookies;
function checkConsentBeforeProceeding() {
  if (hasProvidedConsent()) {
    // Set cookie requirement var based on what is now set
    // C0002 is for performance cookies (https://about.gitlab.com/handbook/marketing/digital-experience/onetrust/#categorizing-cookies)
    // If C0002 (Performance) is unset in OnetrustActiveGroups, then the user declined (Europe only). We cannot then load App Insights
    isApprovedPerformanceCookies = typeof (window as any).OnetrustActiveGroups === 'string' && (window as any).OnetrustActiveGroups.includes('C0002');

    // Tested with a large timeout that this Vue.use will work even after the app has already been mounted (so we can wait for user consent instead delaying app load)
    Vue.use(VueAppInsights, {
      appInsightsConfig: {
        isCookieUseDisabled: !isApprovedPerformanceCookies
        // isStorageUseDisabled: !isApprovedPerformanceCookies, // unlikely to need to disable this for compliance, but just in case audit test rejects due to storage, adding to not have to look up the setting
      },
      baseName: "B&I Connect", // prefix to track route changes as page views with AppInsights
      id: process.env.VUE_APP_INSTRUMENTATION_KEY, // key for dev only
      router,
    });
  
  } else {
    // Keep checking until dialog is closed
    setTimeout(() => {
      checkConsentBeforeProceeding();
    }, 100);
  }
}
checkConsentBeforeProceeding();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

Vue.use(VueIntercom, { appId: `${process.env.VUE_APP_INTERCOM_APP_ID}` });

new Vue({
  router,
  metaInfo: {
    title: "Building Science Connect",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Dow is your materials science partner to help you design and execute the beautiful, safe, sustainable building designs with high performance materials.",
      },
    ],
  },
  vuetify,
  store,
  render: (h) => h(AppComponent),
}).$mount("#app");
