import Vue from "vue";
import Vuex from "vuex";

import { Navigator, P2Interface, P3Interface, P4Interface } from "@/types";
import { urlReplaceSpacesAndLower } from "@/util/url-formatter.utility";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isMobile: false,
    openModal: false,
    isSideDrawerOpened: false,
    city: {
      previousNavigators: [],
      navigateTo: {},
      willZoom: false,
      isZooming: false,
      isZoomed: false,
      navigatorImageFilename: "",
      navigatorImageDarkFilename: "",
      hasNavigatorImageOverlay: false,
      forceDarkBackground: false,
      labelText: "",
    },
    contact: {
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      country: "",
      marketSegment: [],
      reason: "",
      comment: "",
      role: "",
      projectSize: "",
      budgetType: "",
      purchaseProcessStep: "",
      optIn: "",
    }
  },

  getters: {
    isEmailInvalid: (state): boolean => {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        state.contact.email
      )
        ? false
        : true;
    },
    getP2FormValues: (state): P2Interface => {
      return {
        firstName: state.contact.firstName,
        lastName: state.contact.lastName,
        company: state.contact.company,
        country: state.contact.country,
        marketSegment: state.contact.marketSegment,
        reason: state.contact.reason,
        comments: state.contact.comment,
      };
    },
    getP3FormValues: (state): P3Interface => {
      return {
        role: state.contact.role,
        projectSize: state.contact.projectSize,
        budgetType: state.contact.budgetType,
        purchaseProcessStep: state.contact.purchaseProcessStep,
      };
    },
    getP4FormValues: (state): P4Interface => {
      return {
        optIn: state.contact.optIn,
      };
    },
    isContactFormValid: (state): boolean => {
      return (
        !!state.contact.firstName &&
        !!state.contact.lastName &&
        !!state.contact.company &&
        !!state.contact.country &&
        !!state.contact.marketSegment.length &&
        !!state.contact.reason
      );
    },
    isZoomedInComplete: (state): boolean => {
      return (
        !state.city.willZoom && !state.city.isZooming && state.city.isZoomed
      );
    },
    isZoomedInCompleteWithDarkImage: (state): boolean => {
      return (
        !state.city.willZoom &&
        !state.city.isZooming &&
        state.city.isZoomed &&
        !!state.city.navigatorImageDarkFilename
      );
    },
    isZoomedOutToCityComplete: (state): boolean => {
      return (
        !state.city.willZoom &&
        !state.city.isZooming &&
        !state.city.isZoomed &&
        state.city.previousNavigators.length === 0
      );
    },
    isZoomedOutToChildNavigatorComplete: (state): boolean => {
      return (
        !state.city.willZoom &&
        !state.city.isZooming &&
        !state.city.isZoomed &&
        state.city.previousNavigators.length > 0
      );
    },
    zoomedImageFileNames: (
      state
    ): { zoomedInImageFileName: string; zoomedInDarkImageFileName: string } => {
      const zoomedImageFileNames = {
        zoomedInImageFileName: "",
        zoomedInDarkImageFileName: "",
      };

      if (Object.keys(state.city.navigateTo).length > 0) {
        const navigateTo = state.city.navigateTo as Navigator;

        zoomedImageFileNames.zoomedInImageFileName = navigateTo.zoomedImage.url;
        zoomedImageFileNames.zoomedInDarkImageFileName = navigateTo.zoomedInDarkImage
          ? navigateTo.zoomedInDarkImage.url
          : "";
      }

      return zoomedImageFileNames;
    },
  },

  mutations: {
    CITY_NAVIGATE_TO(state, payload: Navigator): void {
      state.city.previousNavigators =
        Object.keys(state.city.navigateTo || {}).length !== 0
          ? [state.city.navigateTo, ...state.city.previousNavigators]
          //there is no clean solution to type the vue store https://stackoverflow.com/a/59092635
          //eslint-disable-next-line
          : ([] as any);
      state.city.navigateTo = payload;
    },
    CITY_NAVIGATE_BACK(state, { innovationsOn, router$ }): void {
      //The previous navigators are a list of the last used state.city.navigateTo's
      const previousNavigators = state.city.previousNavigators as Navigator[];
      //mostRecentPreviousNavigator is the most recent previous navigator
      //updatedPreviousNavigator is the previousNavigators without the most recent previousNavigator
      const [
        mostRecentPreviousNavigator,
        ...updatedPreviousNavigators
      ] = previousNavigators;

      //the innovationsOn and router logic should most likely be abstracted outside of the store
      //but for now this works
      const innovationParam = innovationsOn ? "?innovation=true" : "";

      if (mostRecentPreviousNavigator) {
        state.city.navigateTo = mostRecentPreviousNavigator;
        state.city.navigatorImageFilename =
          mostRecentPreviousNavigator.zoomedImage.url;

        // Immitate the state in the URL but don't affect the app
        state.city.navigatorImageDarkFilename = mostRecentPreviousNavigator.zoomedInDarkImage
          ? mostRecentPreviousNavigator.zoomedInDarkImage.url
          : "";

        const param = urlReplaceSpacesAndLower(
          `${mostRecentPreviousNavigator.id}-${mostRecentPreviousNavigator.identifier}`
        );
        //update the router to match the new navigator
        router$.push(`/city/navigator/${param}/${innovationParam}`);
      } else {
        //if the updatedPreviousNavigators array isnt larger than city it means we have hit the top level of the city
        //so replace the navigateTo with an empty object to reset the navigateTo
        state.city.navigateTo = {};
        state.city.navigatorImageFilename = "";
        state.city.navigatorImageDarkFilename = "";

        //update the router to not have a navigator
        router$.push(`/city/${innovationParam}`);
      }

      //lastly replace the previousNavigators with the new updatedPreviousNavigators array
      //eslint-disable-next-line
      state.city.previousNavigators = updatedPreviousNavigators as any; //eventually need to add an interface to the store so this any isnt needed
    },
    CITY_WILL_ZOOM(state, bool): void {
      //this is for an edge case where the video isnt loaded,
      //but we do not want the buttons after the zoom to show yet
      //note: we cannot use isZooming for this because that shows the finished zoomed background image, before the zoom has taken place
      state.city.willZoom = bool;
    },
    CITY_IS_ZOOMING(state, bool): void {
      state.city.isZooming = bool;
    },
    CITY_IS_ZOOMED(state, bool): void {
      state.city.isZoomed = bool;
    },
    CITY_NAVIGATOR_IMAGE_FILENAME(state, name): void {
      state.city.navigatorImageFilename = name;
    },
    CITY_NAVIGATOR_IMAGE_DARK_FILENAME(state, name): void {
      state.city.navigatorImageDarkFilename = name;
    },
    CITY_NAVIGATOR_HAS_IMAGE_OVERLAY(state, bool): void {
      state.city.hasNavigatorImageOverlay = bool;
    },
    CITY_FORCE_DARK_BACKGROUND(state, bool): void {
      state.city.forceDarkBackground = bool;
    },
    UPDATE_CONTACT_STATE(state, payload): void {
      state.contact[payload.type] = payload.value;
    },
    UPDATE_LABEL_TEXT(state, label): void {
      state.city.labelText = label;
    },
    UPDATE_MOBILE_STATE(state, bool): void {
      state.isMobile = bool;
    },
    UPDATE_OPENMODAL_STATE(state, bool): void {
      state.openModal = bool;
    },
    UPDATE_OPENSIDEDRAWER_STATE(state, bool): void {
      state.isSideDrawerOpened = bool;
    },
    UPDATE_PREVIOUS_NAVIGATORS(state, payload): void {
      state.city.previousNavigators = payload;
    },
    UPDATE_NAVIGATE_TO(state, payload): void {
      state.city.navigateTo = payload;
    }
  },

  actions: {
    updateZoomingStateChange({ commit, getters }, payload): void {
      const { innovationsOn, router$, isZoomingIn } = payload;

      if (isZoomingIn && getters.zoomedImageFileNames.zoomedInImageFileName) {
        commit(
          "CITY_NAVIGATOR_IMAGE_FILENAME",
          getters.zoomedImageFileNames.zoomedInImageFileName
        );

        if (getters.zoomedImageFileNames.zoomedInDarkImageFileName) {
          commit(
            "CITY_NAVIGATOR_IMAGE_DARK_FILENAME",
            getters.zoomedImageFileNames.zoomedInDarkImageFileName
          );
          commit("CITY_NAVIGATOR_HAS_IMAGE_OVERLAY", true);
        } else {
          commit("CITY_NAVIGATOR_IMAGE_DARK_FILENAME", "");
          commit("CITY_NAVIGATOR_HAS_IMAGE_OVERLAY", false);
        }
      } else {
        commit("CITY_NAVIGATE_BACK", { innovationsOn, router$ });
      }
    },
    navigateForward({ commit, getters }, payload): void {
      commit("CITY_WILL_ZOOM", true);
      commit("CITY_NAVIGATE_TO", payload);
      commit("UPDATE_LABEL_TEXT", payload.labelText);
    },
    setCityStateFromDirectLink(
      { commit, getters },
      navigator: Navigator
    ): void {
      commit(
        "UPDATE_PREVIOUS_NAVIGATORS",
        navigator.parentNavigator ? [navigator.parentNavigator] : []
      );
      commit("UPDATE_NAVIGATE_TO", navigator);
      commit("CITY_NAVIGATOR_IMAGE_FILENAME", navigator.zoomedImage.url);

      if (navigator.zoomedInDarkImage && navigator.zoomedInDarkImage.url) {
        commit(
          "CITY_NAVIGATOR_IMAGE_DARK_FILENAME",
          navigator.zoomedInDarkImage ? navigator.zoomedInDarkImage.url : ""
        );
        commit("CITY_NAVIGATOR_HAS_IMAGE_OVERLAY", true);
        commit("");
      } else {
        commit("CITY_NAVIGATOR_IMAGE_DARK_FILENAME", "");
        commit("CITY_NAVIGATOR_HAS_IMAGE_OVERLAY", false);
      }

      commit("CITY_IS_ZOOMED", true);
    },
  },
});

export default store;
