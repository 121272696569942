






































import { Component, Vue } from "vue-property-decorator";

import DataPrivacyContent from "@/components/contact/DataPrivacyContent.vue";

import { DCSLanguageService } from "@/services";

@Component({
  components: {
    DataPrivacyContent,
  },
})
export default class ContactEntryP1 extends Vue {
  public email = "";

  public emailRules = [
    (v): string | boolean => !!v || "E-mail is required",
    (v): string | boolean =>
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        v
      ) || "Invalid Email Address",
  ];
  public DCSLanguageService = new DCSLanguageService();

  public routeToContactP2(): void {
    this.$emit("submit-form", {
      formValues: { email: this.$store.state.contact.email },
      submit: false,
    });
    this.$emit("navigate-form", "ContactEntryP2");
  }

  public sendToStore(e): void {
    this.$store.commit("UPDATE_CONTACT_STATE", { type: "email", value: e });
  }
}
