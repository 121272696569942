











































import { Component, Prop, Vue } from "vue-property-decorator";
import { blobDataPath } from "@/util/blob-data-path.utility";
import { MobileDetail } from "@/types";

@Component
export default class HeroComponent extends Vue {
  @Prop()
  public appData!: MobileDetail;

  public customTracker(desc): void {
    this["$appInsights"].appInsights.trackEvent({
      name: `${desc}`,
      properties: {
        page: "Landing Page",
        taggedItemType: "Button",
        taggedItemText: "APPLICATION NAME - Button",
      },
    });
    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Link Tracking",
      link: {
        linkName: `${desc}`, // usually link text or other identifier
        linkPosition: "Mobile - Application Button", // location of link on page
        linkPage: "Mobile Home", // usually `page.pageInfo.pageName` from `Page Viewed` event
      },
    });
  }

  public getMediaPath(img): string {
    return blobDataPath + img.url;
  }
}
