/* tslint:disable */
/* eslint-disable */
import { Observable, Subject } from "rxjs";

export class AdobeTrackingService {
  private satellite$: Subject<any> = new Subject();

  constructor() {}

  public satellite(): Observable<any> {
    return this.satellite$.asObservable();
  }

  public init(): void {
    const scriptElement = document.createElement("script");
    const { NODE_ENV } = process.env;

    scriptElement.onload = (): void => {
      // Detect if cookie tracking disables the tracking cookies and set that configuration in Adobe Analytics tracking
      // If C0002 (Performance) is unset in OnetrustActiveGroups, then the user declined (Europe only). We cannot then load Adobe Analytics
      const isApprovedTargetingCookies = typeof (window as any).OnetrustActiveGroups === 'string' && (window as any).OnetrustActiveGroups.includes('C0002');
      if (!isApprovedTargetingCookies) {
        (window as any)._satellite.cookie.set('sat_track', 'false');
      }

      (window as any).digitalData = {
        page: {
          PageInfo: {
            customName: "BIConnect",
            name: "BIConnect",
          },
        },
      };
      (window as any)._satellite.pageBottom();

      this.satellite$.next((window as any)._satellite);
    };

    // If the script is cached, then as soon as you add the src the item is loaded and onload does not fire. Adding the onload before the src will ensure that onload fires for cached scripts
    if (NODE_ENV === "test" || NODE_ENV === "production") {
      scriptElement.src =
        "//assets.adobedtm.com/55f90c612c0d/b6b1b7651476/launch-1860d970180a.min.js";
    } else {
      scriptElement.src =
        "//assets.adobedtm.com/55f90c612c0d/b6b1b7651476/launch-a29ba72bb4a6-staging.min.js";
    }

    document.head.appendChild(scriptElement);
  }
}
