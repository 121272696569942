var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("SideDrawerComponent"),
      _c("MobileNavComponent"),
      !_vm.headerState.modalActive
        ? _c(_vm.header, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.headerState.isMobileHome &&
                  !_vm.$store.state.city.isZooming &&
                  !_vm.$store.state.isSideDrawerOpened &&
                  !_vm.headerState.isOnRoot,
                expression:
                  "\n      !headerState.isMobileHome &&\n        !$store.state.city.isZooming &&\n        !$store.state.isSideDrawerOpened &&\n        !headerState.isOnRoot\n    "
              }
            ],
            tag: "component",
            attrs: {
              "is-on-root-path": _vm.headerState.isOnRoot,
              "show-explore-city": _vm.headerState.isExploreCity
            }
          })
        : _vm._e(),
      _c(
        "v-main",
        [
          _c("router-view"),
          _c(
            "div",
            [
              _vm.contactFormOpen ? _c("ContactComponent") : _vm._e(),
              _vm.$store.state.city.forceDarkBackground
                ? _c("CityDarkBGComponent", {
                    attrs: {
                      "navigator-image-dark-filename": _vm.$store.state.city
                        .hasNavigatorImageOverlay
                        ? _vm.$store.state.city.navigatorImageDarkFilename
                        : _vm.$store.state.city.navigatorImageFilename,
                      "modifier-class": "dark-bg--fixed"
                    }
                  })
                : _vm._e(),
              _c("CityBGComponent", {
                attrs: {
                  "navigator-image-filename":
                    _vm.$store.state.city.navigatorImageFilename,
                  "modifier-class": "cityscape--static-backing"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }