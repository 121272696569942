import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';

import City from '../views/City/City.vue';
import Mobile from '../views/Mobile/Mobile.vue';

Vue.use(VueRouter);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    redirect: "/city",
  },
  // DCS Routes
  {
    path: "/city",
    name: "City",
    component: City,
    props: (route): { innovation: string | (string | null)[] } => ({
      innovation: route.query.innovations,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/city/navigator/:navigator",
    name: "CityNavigator",
    component: City,
    props: (route): { innovation: string | (string | null)[] } => ({
      innovation: route.query.innovations,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: Mobile,
  },
  {
    path: "/webinars",
    name: "Webinars",
    component: (): Promise<typeof import("*.vue")> =>
      import("../views/Webinar/Webinars.vue"),
  },
  {
    path: "/events",
    name: "Upcoming Events",
    component: (): Promise<typeof import("*.vue")> =>
      import("../views/Event/Events.vue"),
  },
  {
    path: "/new-products",
    name: "NewProducts",
    component: (): Promise<typeof import("*.vue")> =>
      import("../views/NewProduct/NewProducts.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: (): Promise<typeof import("*.vue")> =>
      import("../views/NewsArticle/NewsArticles.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: (): Promise<typeof import("*.vue")> =>
      import("../views/Contact/Contact.vue"),
  },
  {
    path: "/product/:name",
    name: "Product",
    component: (): Promise<typeof import("*.vue")> =>
      import("../views/Product-Detail/ProductDetail.vue"),
  },
  {
    path: "/list/:categoryLabel",
    name: "ProductList",
    component: (): Promise<typeof import("*.vue")> =>
      import("../views/Product-List/ProductList.vue"),
  },
  {
    path: "*",
    meta: {
      hardRedirectToCity: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // Update canonical tag on each route
  const scriptElement = document.querySelector('link[rel="canonical"]');

  const baseURL = 'https://www.biconnect.dow.com';
  const link = baseURL + to.path;

  if (scriptElement !== null) {
    scriptElement.setAttribute('href', link);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (window.innerWidth <= 960 && to.path === '/city') {
      next({ name: 'Mobile' });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.hardRedirectToCity)) {
    // Due to vue route caching this needs to be a browser redirect
    window.location.href = '/';
  } else {
    next();
  }
});

export default router;
