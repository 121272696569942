import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  // treeShake: true,
  theme: {
    themes: {
      light: {
        primary    : '#2E6A8E', // $color-app-blue
        content    : '#415364',
        secondary  : '#e80033', // $color-dow-red
        white      : '#FFFFFF',
        darkBlue   : '#193F5E',
        teal       : '#009083',
        green      : "#00A196",
        black      : "#000000" 
      }
    }
  },
});
