










































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ModalCarouselItemComponent extends Vue {
  @Prop()
  public itemInformation;
  @Prop()
  public position;
}
