var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "zoomed-navigators-container" },
    [
      _c("ZoomContentComponent", {
        attrs: {
          "left-align": _vm.navigateTo.cardLeftAlign,
          heading: _vm.navigateTo.labelText,
          description: _vm.navigateTo.cardDescription
        }
      }),
      _vm.navigateTo.childNavigators &&
      _vm.navigateTo.childNavigators.length > 0
        ? _c("NavigatorsComponent", {
            attrs: {
              "data-dragscroll": "",
              navigators: _vm.navigateTo.childNavigators
            }
          })
        : _vm._e(),
      (!_vm.navigateTo.childNavigators ||
        _vm.navigateTo.childNavigators.length <= 0) &&
      _vm.navigateTo.subNavigators &&
      _vm.navigateTo.subNavigators.length > 0
        ? _c("SubNavigatorsComponent", {
            attrs: {
              "data-dragscroll": "",
              "sub-navigators": _vm.navigateTo.subNavigators
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }