













































import { Component, Prop, Vue } from "vue-property-decorator";

import { DCSLanguageService } from "@/services";
import { Navigator, SubNavigator } from "@/types";

@Component
export default class BuildingNavigatorComponent extends Vue {
  @Prop()
  public navigator!: Navigator | SubNavigator;

  public DCSLanguageService = new DCSLanguageService();

  public get isInnovationSelected(): boolean {
    return this.$route.query.innovation ? true : false;
  }

  public async clickHandler(): Promise<void> {
    if (
      this.navigator &&
      Object.prototype.hasOwnProperty.call(this.navigator, "childNavigators")
    ) {
      this.$store.commit("CITY_FORCE_DARK_BACKGROUND", false);
    } else {
      this.$store.commit("CITY_FORCE_DARK_BACKGROUND", true);

      // The timeout ensures the darkening transitions are finished before the route triggers
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve(true);
        }, 250)
      );
    }

    this.$emit("click", this.navigator);
  }
}
