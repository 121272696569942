var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "zoom-content-container",
      class: _vm.leftAlign
        ? "zoom-content-container--left"
        : "zoom-content-container--right"
    },
    [
      _c(
        "v-card",
        { staticClass: "px-3" },
        [
          _c("v-card-text", [
            _c("h1", { staticClass: "primary--text" }, [
              _c("strong", [_vm._v(_vm._s(_vm.heading))])
            ]),
            _c("p", { staticClass: "text--primary" }, [
              _vm._v(" " + _vm._s(_vm.description) + " ")
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }