var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper content--text" },
    [
      _c("div", { staticClass: "banner" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("div", { staticClass: "content-overlay" }, [
              _c(
                "div",
                [
                  _c(
                    "v-container",
                    { staticClass: "page-wrap mobile" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mobile-header" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "logo-col", attrs: { cols: "2" } },
                            [
                              _c(
                                "a",
                                { attrs: { href: "https://www.dow.com" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/logos/dow-diamond.svg")
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _c("v-col", [
                            _c(
                              "div",
                              { staticClass: "menu" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2 primary--text",
                                    attrs: { outlined: "", fab: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.$root.$emit("drawer", true)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-menu ")])],
                                  1
                                ),
                                _vm._v(" | "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2 primary--text",
                                    attrs: { outlined: "", fab: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.localeDrawer = !_vm.localeDrawer
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v(" language ")])],
                                  1
                                ),
                                _vm._v(" | "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mr-2 primary--text",
                                    attrs: { outlined: "", fab: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.$root.$emit(
                                          "contact-form",
                                          true
                                        )
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-forum ")])],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-navigation-drawer",
                        {
                          attrs: {
                            right: "",
                            temporary: "",
                            app: "",
                            width: "100%"
                          },
                          model: {
                            value: _vm.localeDrawer,
                            callback: function($$v) {
                              _vm.localeDrawer = $$v
                            },
                            expression: "localeDrawer"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "locale-drawer-container" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "back",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.localeDrawer = !_vm.localeDrawer
                                    }
                                  }
                                },
                                [_vm._v(" arrow_back ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "locale-selector-container" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/world_map_.svg")
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "information-text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.languageService.translate(
                                              "WeReShowing"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "question-text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.languageService.translate(
                                            "ChangeLocation"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "locale-selector-dropdown",
                                                      attrs: { depressed: "" }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.selectedLocaleName
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("v-icon", [
                                                    _vm._v(" arrow_drop_down ")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        _vm._l(_vm.locales, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: index,
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.setLocale(
                                                    item.name
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticStyle: {
                                                    color: "#265A7D"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.displayLanguageDropDown
                                    ? _c(
                                        "div",
                                        { staticClass: "question-text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.languageService.translate(
                                                  "ChangeLocale"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.displayLanguageDropDown
                                    ? _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "locale-selector-dropdown",
                                                            attrs: {
                                                              depressed: ""
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.selectedLanguageName
                                                              ) +
                                                              " "
                                                          )
                                                        ]),
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            " arrow_drop_down "
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1944895481
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            _vm._l(_vm.languages, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: index,
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.setLanguage(
                                                        item.name
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticStyle: {
                                                        color: "#265A7D"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.name) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("div", { staticClass: "logo-container" }, [
                        _c("img", {
                          attrs: { src: require("@/assets/logos/wm-bsc.svg") }
                        }),
                        _c("div", { staticClass: "divider" })
                      ]),
                      _c(
                        "div",
                        { staticClass: "content-text hero" },
                        [
                          _c("h3", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.languageService.translate(
                                    "InspireTheFuturescape"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.languageService.translate(
                                    "ExploreProducts"
                                  )
                                ) + " "
                              )
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.languageService.translate("BrowseDowsil")
                              ) + " "
                            )
                          ]),
                          _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.languageService.translate("LearnMore")
                                ) + " "
                              )
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.languageService.translate(
                                  "TechnicalInformation"
                                )
                              ) + " "
                            )
                          ]),
                          _c("p", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.languageService.translate("Connect")
                                ) + ": "
                              )
                            ]),
                            _vm._v(
                              _vm._s(
                                _vm.languageService.translate(
                                  "TechnicalInformation"
                                )
                              ) + " "
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticStyle: {
                                "z-index": "2",
                                "margin-bottom": "20px"
                              },
                              attrs: { color: "primary", rounded: "" },
                              on: {
                                click: function($event) {
                                  _vm.localeDrawer = !_vm.localeDrawer
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "24px",
                                    "padding-right": "10px"
                                  }
                                },
                                [_vm._v(" language ")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.languageService.translate(
                                      "SelectRegion"
                                    )
                                  ) +
                                  " "
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "footnote" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.languageService.translate(
                                    "CurrentlyShowing"
                                  )
                                ) +
                                " " +
                                _vm._s(
                                  _vm.languageService.translate(
                                    _vm.selectedLocaleName
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.languageService.translate("In")) +
                                " " +
                                _vm._s(
                                  _vm.languageService.translate(
                                    _vm.selectedLanguageName
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("h3", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.languageService.translate("WhatsNew")
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "what-is-new" },
                            [
                              [
                                _c(
                                  "v-chip",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleUpcomingEventsClick()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("event")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.languageService.translate(
                                            "UpcomingEvents"
                                          )
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-chip",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleNewsClick()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("newspaper")]),
                                    _vm._v(" News ")
                                  ],
                                  1
                                ),
                                _c(
                                  "v-chip",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleNewProductsClick()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("new_releases")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.languageService.translate(
                                            "NewProducts"
                                          )
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "fab" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "tour",
                              staticStyle: { "z-index": "2" },
                              attrs: { rounded: "", color: "green" },
                              on: {
                                click: function($event) {
                                  _vm.$vuetify.goTo("#applications")
                                  _vm.customTracker("Tour the City CTA Button")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.languageService.translate("TourTheCity")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { height: "380px" } },
                        [
                          _c("v-img", {
                            staticStyle: { height: "580px", top: "-110px" },
                            attrs: {
                              src:
                                _vm.blobDataPath +
                                "/uploads/city_start_v3_4_8_2022_dec094490a.jpg"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "content-text applications",
                          attrs: { id: "applications" }
                        },
                        [
                          _c("h3", { staticClass: "top" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.languageService.translate("Applications")
                                ) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.languageService.translate("BrowseThrough")
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "chips" },
                            [
                              !_vm.chips || _vm.chips.length <= 0
                                ? _vm._l(8, function(index) {
                                    return _c("v-skeleton-loader", {
                                      key: index,
                                      staticClass: "chip-loader",
                                      attrs: { type: "chip" }
                                    })
                                  })
                                : _vm._e(),
                              _vm.chips
                                ? _vm._l(_vm.chips, function(chip, index) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: index,
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function($event) {
                                            _vm.$vuetify.goTo(chip.route)
                                            _vm.customTracker(
                                              chip.desc + " Button"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(chip.icon))
                                        ]),
                                        _vm._v(" " + _vm._s(chip.desc) + " ")
                                      ],
                                      1
                                    )
                                  })
                                : _vm._e()
                            ],
                            2
                          ),
                          _c("p", { staticClass: "note" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.languageService.translate(
                                    "YouCanAlsoView"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "see-all",
                              attrs: {
                                rounded: "",
                                block: "",
                                color: "dow-red"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleSeeAllInnovationsClick()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.languageService.translate(
                                      "SeeAllInnovations"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "divider" }),
                          _c(
                            "div",
                            { staticClass: "mobile-popup-wrapper" },
                            [
                              _c("v-img", {
                                staticClass: "img",
                                attrs: {
                                  "max-width": "80",
                                  "max-height": "80",
                                  src: require("@/assets/AW.png")
                                }
                              }),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.languageService.translate(
                                        "WeCelebrate"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.mobileArray, function(app, index) {
                        return _c("HeroComponent", {
                          key: index,
                          attrs: { "app-data": app }
                        })
                      }),
                      _c(
                        "footer",
                        { staticClass: "mobile-footer" },
                        [
                          _c("h4", [
                            _vm._v(
                              _vm._s(
                                _vm.languageService.translate("ExploreBuilding")
                              )
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2",
                              attrs: { outlined: "", color: "white" },
                              on: {
                                click: function($event) {
                                  return _vm.customTracker(
                                    "menu - visit Dow.com"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.languageService.translate("Visit")
                                  ) +
                                  " Dow.com "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "sub-footer" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.customTracker(
                                      "menu - contact us"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.languageService.translate("ContactUs")
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.customTracker(
                                      "menu - privacy statement"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.languageService.translate(
                                        "PrivacyStatement"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.customTracker(
                                      "menu - terms of use"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.languageService.translate(
                                        "TermsOfUse"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c("p", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.languageService.translate("Copyright")
                                  ) +
                                    " © Dow (1995-" +
                                    _vm._s(_vm.year) +
                                    "). " +
                                    _vm._s(
                                      _vm.languageService.translate("AllRights")
                                    )
                                )
                              ]),
                              _c("span", [
                                _vm._v(
                                  "® ™ " +
                                    _vm._s(
                                      _vm.languageService.translate("Trademark")
                                    )
                                )
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c("CityDarkBGComponent")
          ],
          1
        )
      ]),
      _c("CityBGComponent", {
        attrs: {
          index: -1,
          position: "absolute",
          "modifier-class": "cityscape--home"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }