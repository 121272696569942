import { AxiosResponse } from "axios";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { http } from "../http-client";
import { serializeStrapiResponse } from "@/util/seralize.utility";

import { DCSLanguageService } from "@/services";
import { Webinar } from "@/types/webinar.interface";

export class WebinarService {
  private DCSLanguageService = new DCSLanguageService();
  /**
   * GET from list of Webinar videos
   */
  public getWebinarVideoList(): Observable<Webinar[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() => http.get(`/dcs-language/webinars/${languageID}`)).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((webinars) =>
        webinars.map((webinar) => serializeStrapiResponse(webinar) as Webinar)
      )
    );
  }
}
