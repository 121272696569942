import axios, { AxiosResponse } from 'axios';

import { ContactEloqua, ContactEloquaRemapped } from '@/types';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_ELOQUA_AZ_FUNC_URI,
  headers: {
    "Content-Type": "application/json"
  }
});


export class EloquaService {
  public postEloquaFormData(eloquaData: ContactEloqua): Promise<AxiosResponse<unknown>> {
    const eloquaRemapped = new ContactEloquaRemapped(eloquaData);
    const payload        = JSON.stringify(eloquaRemapped);

    return httpClient.post('', payload);
  }
}
