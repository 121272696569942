var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-complete-wrapper" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "cta-wrapper" },
      [
        _c("header", [
          _vm._v(
            _vm._s(_vm.DCSLanguageService.translate("WeLookForward")) + "!"
          )
        ]),
        _c("p", [
          _vm._v(_vm._s(_vm.DCSLanguageService.translate("WellReachOut")) + ".")
        ]),
        _c(
          "v-btn",
          {
            staticClass: "white--text green elevation-0",
            on: {
              click: function($event) {
                return _vm.closeDrawer()
              }
            }
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.DCSLanguageService.translate("LetsExplore")) +
                "! "
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "data-privacy--stick-bottom" },
      [_c("DataPrivacyContent")],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-wrapper" }, [
      _c("img", { attrs: { src: require("@/assets/sending_emails.svg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }