var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "div",
                    _vm._g(
                      _vm._b({ staticClass: "dropdown" }, "div", attrs, false),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.DCSLanguageService.translate("MoreResources")
                          ) +
                          " "
                      ),
                      _c("v-icon", [_vm._v(" mdi-menu-down ")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { on: { click: _vm.helpClicked } },
                [
                  _c("v-list-item-title", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.DCSLanguageService.translate("Help")) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _vm._l(_vm.resourceLinks, function(link) {
                return _c(
                  "v-list-item",
                  {
                    key: link.id,
                    staticClass: "cursor",
                    attrs: { href: link.url, target: "_blank" }
                  },
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(link.label) + " ")
                    ])
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }