
































































































































import { Component, Vue } from "vue-property-decorator";

import ContactHeaderBarComponent from "@/components/contact/sub-components/ContactHeaderBar.vue";

import { DCSLanguageService } from "@/services";

import * as countries from "i18n-iso-countries";

@Component({
  components: {
    ContactHeaderBarComponent,
  },
})
export default class ContactEntryP2 extends Vue {
  public countriesList: string[] = [];
  public DCSLanguageService = new DCSLanguageService();

  private created(): void {
    /* eslint @typescript-eslint/no-var-requires: "off" */
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    const countriesDictionary: object = countries.getNames("en", {
      select: "official",
    });

    Object.keys(countriesDictionary).forEach((key, i) => {
      this.countriesList[i] = countriesDictionary[key];
    });
  }

  // todo: Once translations are given, add these to the DCSLanguage Service with
  // a getter to grab correct translation
  public marketSegments = [
    "Building Envelope",
    "Building Materials and Components",
    "Commercial Glazing",
    "Construction Chemicals",
    "Flooring and Surfaces",
    "Infrastructure",
    "Roofing",
    "Wall Systems, Insulation and Facade",
    "Window, Door and Building Elements",
  ];

  public reasons = [
    "Ready to Place Order",
    "Request Pricing / Availability",
    "Request Sales Call",
    "Request Technical Support",
    "Conduct Testing / Technical Review",
    "Request Sample",
    "Collect / Set Specifications",
    "Get Certification / Regulatory Info",
    "Get Product Recommendation",
    "Get Product / Technical Information",
    "Other",
  ];

  public routeToContactP4(): void {
    this.$emit("submit-form", {
      formValues: { email: this.$store.state.contact.email },
      submit: false,
    });
    this.$emit("navigate-form", "ContactEntryP2");
  }

  public submitP2FormValues(submit: boolean): void {
    const formValues = this.$store.getters.getP2FormValues;
    this.passRouteToContactComponent(formValues, submit);
  }

  public passRouteToContactComponent(
    formValues: object,
    submit: boolean
  ): void {
    this.$emit("submit-form", {
      formValues: formValues,
      submit: false,
    });

    if (!submit) {
      this.$emit("navigate-form", "ContactEntryP3");
    } else {
      this.$emit("navigate-form", "ContactEntryP4");
    }
  }

  public sendToStore(e, type): void {
    this.$store.commit("UPDATE_CONTACT_STATE", { type, value: e });
  }
}
