























import { Vue, Component } from "vue-property-decorator";
import { blobDataPath } from "@/util/blob-data-path.utility";

const HelpModalPageProps = Vue.extend({
  props: {
    title: { type: String, default: "" },
    text1: { type: String, default: "" },
    bold: { type: String, default: "" },
    text2: { type: String, default: "" },
    text3: { type: String, default: "" },
    img: {
      type: Object,
      default: (): object => {
        return {};
      },
    },
  },
});

@Component({
  components: {},
})
export default class HelpModalPageComponent extends HelpModalPageProps {
  public imageSrc = "";

  private created(): void {
    if (this.img) {
      this.imageSrc = blobDataPath + this.img["url"];
    }
  }

  public modalClosed(): void {
    this.$emit("close", false);
  }
}
