











import { Component, Vue, PropSync } from "vue-property-decorator";

@Component({})
export default class DropdownComponent extends Vue {
  @PropSync("selected", { type: String }) syncedSelected!: string;
  @PropSync("items", { type: Array }) syncedItems!: string[];

  selectChange(value: string): void {
    this.$emit('selectChange', value);
  }
}
