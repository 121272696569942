var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "white--text",
      attrs: {
        value: _vm.isSideDrawerOpened,
        absolute: "",
        temporary: "",
        color: "#193f5eed",
        "overlay-opacity": ".1",
        dark: "",
        width: "624"
      },
      on: { input: _vm.updateSideDrawerState }
    },
    [
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { "pa-4": "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/logos/dow-diamond.svg"),
                      "max-width": "110"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "4" } },
                [
                  _c("DropdownComponent", {
                    staticClass: "pa-0",
                    attrs: {
                      "v-if": _vm.selectedLocaleName,
                      selected: _vm.selectedLocaleName,
                      items: _vm.localeNames
                    },
                    on: {
                      selectChange: function($event) {
                        return _vm.setLocale($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm.displayLanguageDropDown
                ? _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "4" } },
                    [
                      _c("DropdownComponent", {
                        staticClass: "pa-0",
                        attrs: {
                          selected: _vm.selectedLanguageName,
                          items: _vm.languageNames
                        },
                        on: {
                          selectChange: function($event) {
                            return _vm.setLanguage($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "overlay", attrs: { "pa-6": "" } },
            [
              _c("h1", { staticClass: "drawer-title" }, [
                _vm._v(" Building Science ")
              ]),
              _c(
                "h4",
                {
                  staticClass: "explore-text",
                  on: {
                    click: function($event) {
                      return _vm.updateSideDrawerState(false)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.languageService.translate("ExploreCityscape")
                      ) +
                      " → "
                  )
                ]
              ),
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " + _vm._s(_vm.languageService.translate("BuildWith")) + " "
                )
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.languageService.translate("ExploreOur")) + " "
                )
              ]),
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " + _vm._s(_vm.languageService.translate("LetUs")) + " "
                )
              ]),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    attrs: {
                                      elevation: hover ? 6 : 2,
                                      to: "/events"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "align-center",
                                        attrs: {
                                          src: require("@/assets/upcoming_events.png"),
                                          height: "128px"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "justify-center" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-caption text-uppercase"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.languageService.translate(
                                                        "UpcomingEvents"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    attrs: {
                                      elevation: hover ? 6 : 2,
                                      to: "/new-products"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "align-center",
                                        attrs: {
                                          src: require("@/assets/new_products.png"),
                                          height: "128px"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "justify-center" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-caption text-uppercase"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.languageService.translate(
                                                        "NewProducts"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    attrs: {
                                      elevation: hover ? 6 : 2,
                                      to: "/news"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "align-center",
                                        attrs: {
                                          src: require("@/assets/news.jpg"),
                                          height: "128px"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "justify-center" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-caption text-uppercase"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.languageService.translate(
                                                        "News"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c("p", { staticClass: "text-caption text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.languageService.translate("Copyright")) +
                    " © Dow (1995-" +
                    _vm._s(new Date().getFullYear()) +
                    "). " +
                    _vm._s(_vm.languageService.translate("AllRights")) +
                    " ®™ " +
                    _vm._s(_vm.languageService.translate("Trademark")) +
                    ". "
                )
              ]),
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            href:
                              "https://www.linkedin.com/showcase/dow-building-science",
                            target: "_blank"
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-linkedin ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("span", [_vm._v("|")])
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            href: "https://twitter.com/DowBScience/",
                            target: "_blank"
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-twitter ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "auto" } }, [_vm._v(" | ")]),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            href:
                              "https://legal.dow.com/en-us/privacy-statement",
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.languageService.translate(
                                  "PrivacyStatement"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "auto" } }, [_vm._v(" | ")]),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            href: "https://legal.dow.com/en-us/terms-of-use",
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.languageService.translate("TermsOfUse")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "auto" } }, [_vm._v(" | ")]),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: {
                            text: "",
                            small: "",
                            href: "https://www.dow.com",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" Dow.com ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }