var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "dragscroll",
          rawName: "v-dragscroll:nochilddrag",
          arg: "nochilddrag"
        }
      ],
      ref: "cityWrapper",
      staticClass: "city-wrapper"
    },
    [
      _c(
        "div",
        {
          staticClass: "city-scrollable-container",
          class: { hidden: _vm.showModal },
          attrs: { "data-dragscroll": "" }
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade-transition" } },
            [
              _vm.$store.getters.isZoomedOutToCityComplete
                ? _c("NavigatorsComponent", {
                    attrs: { "data-dragscroll": "" }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade--zoomed-navigators" } },
            [
              _vm.$store.getters.isZoomedInComplete ||
              _vm.$store.getters.isZoomedOutToChildNavigatorComplete
                ? _c("ZoomedNavigatorsComponent", {
                    attrs: { "navigate-to": _vm.$store.state.city.navigateTo }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("CityBGComponent", {
            attrs: {
              "data-dragscroll": "",
              "navigator-image-filename":
                _vm.$store.state.city.navigatorImageFilename,
              "modifier-class": _vm.$store.state.city.isZooming
                ? "cityscape--video-zooming"
                : _vm.$store.getters.isZoomedInCompleteWithDarkImage
                ? "cityscape--forward"
                : null
            }
          }),
          _c(
            "transition",
            { attrs: { name: "fade--background-dark", mode: "out-in" } },
            [
              _vm.$store.getters.isZoomedInCompleteWithDarkImage
                ? _c("CityDarkBGComponent", {
                    attrs: {
                      "data-dragscroll": "",
                      "navigator-image-dark-filename":
                        _vm.$store.state.city.navigatorImageDarkFilename,
                      "modifier-class": "dark-bg--fixed"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("CityZoomerComponent", {
            attrs: {
              "data-dragscroll": "",
              "navigate-to": _vm.$store.state.city.navigateTo
            }
          })
        ],
        1
      ),
      _vm.showModal
        ? _c("HelpModalComponent", {
            attrs: { visible: _vm.showModal },
            on: {
              close: function($event) {
                _vm.showModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }