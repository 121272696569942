export interface Media {
  type: MediaType;
  sources: StrapiMedia[];
  poster: StrapiMedia;
  autoPlay?: boolean; // For gif & video, if should play on load, or wait for some event on the component
  isExternal?: boolean; // If external, then the sources will not use blobDataPath and just output src path
}

export interface CarouselItem {
  title: string;
  description: string;
  isFeatured: boolean;
}

export interface SingleMediaCarouselItem extends CarouselItem {
  media: CarouselMedia;
}

export interface MultiMediaCarouselItem extends CarouselItem {
  media: CarouselMedia[];
}

export interface CarouselMedia {
  mediaType: MediaType;
  videoUrl?: string;
  image: StrapiMedia;
}

export const DEFAULT_CAROUSEL_IMAGE_URL = "/assets/logos/dow-diamond.svg";

export interface StrapiMedia {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats?: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: string;
  provider_metadata?: unknown;
  provider: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Formats {
  thumbnail: Image;
  large: Image;
  medium: Image;
  small: Image;
}

interface Image {
  name: string;
  hash: string;
  ext: string;
  mime: string;
  width: number;
  height: number;
  size: number;
  path: string;
  url: string;
}

enum MediaType {
  image = 'image',
  gif = 'gif',
  video = 'video'
}