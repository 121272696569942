




























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

import { blobDataPath, createProductUrl, createListUrl } from "@/util";

import CityBGComponent from "@/components/core/backgrounds/CityBG.vue";
import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import HeroComponent from "@/components/mobile/HeroComponent.vue";
import AnniversaryPopupComponent from "@/components/city/AnniversaryPopup.vue";
import DropdownComponent from "@/components/core/headers/sub-components/DropdownComponent.vue";

import { CityNavigatorService, LocaleService, DCSLanguageService } from "@/services";
import { DCSLanguage, MobileDetail, MobileDetailItem, Navigator, SubNavigator } from "@/types";
import { Locale } from "@/types/locale.interface";

@Component({
  components: {
    HeroComponent,
    AnniversaryPopupComponent,
    CityBGComponent,
    CityDarkBGComponent,
    DropdownComponent
  },
})
export default class MobileComponent extends Vue {

  private localeService = new LocaleService();
  public selectedLocaleName = "";
  public locales!: Locale[];
  public localeDrawer = false;

  public languageService = new DCSLanguageService();
  public selectedLanguageName = "";
  public displayLanguageDropDown = false;
  public languages!: DCSLanguage[];

  public chips: { icon: string; desc: string; route: string }[] = [];

  public blobDataPath = blobDataPath;

  public year = new Date().getFullYear();

  public customTracker(desc): void {
    this["$appInsights"].appInsights.trackEvent({ name: `${desc}` });
    // eslint-disable-next-line
    (window as any).dataLayer.push({
      event: "Link Tracking",
      link: {
        linkName: `${desc}`, // usually link text or other identifier
        linkPosition: "Mobile Button", // location of link on page
        // eslint-disable-next-line
        //linkPage: (window as any).page.pageInfo.pageName, // usually `page.pageInfo.pageName` from `Page Viewed` event
        linkPage: "test123",
      },
    });
  }

  public mobileArray: MobileDetail[] = [];

  private cityNavigatorService = new CityNavigatorService();

  private async created(): Promise<void> {
    if (window.screen.width <= 960) {
      this.$store.commit("UPDATE_MOBILE_STATE", true);
      this.$store.commit("UPDATE_LABEL_TEXT", "Home");
    } else {
      this.$store.commit("UPDATE_MOBILE_STATE", false);
    }

    this.locales = await this.localeService.getLocales().toPromise();

    const locale = this.localeService.getLocale();
    if (locale) {
      this.selectedLocaleName = locale.name;
    } else {
      this.selectedLocaleName = this.locales[0].name;
    }

    this.languages = await this.languageService.getDCSLanguages().toPromise();
    if (this.languages.length > 1) {
      this.displayLanguageDropDown = true;
    }

    this.selectedLanguageName = this.languageService.getDCSLanguage().name;

    this.cityNavigatorService
      .getNavigators()
      .subscribe((navigators) => {
        const parentNavigators = navigators.filter(navigator => !navigator.parentNavigator);
        
        this.mobileArray = this.getMobileData(navigators, parentNavigators);
        this.chips = parentNavigators.map((navigator) => {
          return {
            icon: navigator.iconName ?? 'MISSING ICON NAME',
            desc: navigator.labelText ?? 'MISSING LABEL',
            route: `#${this.createRouteId(navigator.id)}`
          }
        })
      });
  }

  private getMobileData(allNavigators: Navigator[], parentNavigators: Navigator[]): MobileDetail[] {
    const partialMobileDetails = parentNavigators
      .map(navigator => ({
          data: {
            identifier: this.createRouteId(navigator.id),
            title: navigator.labelText,
            description: navigator.cardDescription,
            listIcon: navigator.iconName,
            image: navigator.zoomedInDarkImage ?? navigator.zoomedImage,
          } as Partial<MobileDetail>,
          subNavigators: this.getSubNavigators(allNavigators, navigator),
        }));
    const mobileDetails = partialMobileDetails.map(({data, subNavigators}) => this.transformToMobileDetails(data, subNavigators));

    return mobileDetails;
  }

  private transformToMobileDetails(data: Partial<MobileDetail>, subNavigators: SubNavigator[]): MobileDetail {
    const mobileDetailItems: MobileDetailItem[] = [];
    for (const subNavigator of Object.values(subNavigators)) {
      //products & templates are some times listed multiple times under a parent navigator (eg horizontal joints being in airport & residential)
      //on the mobile view all the products and templates are listed out, which would show duplicates
      //So we will skip any mobile details with duplicate labels
      const sameLabelAlreadyExists = mobileDetailItems.find(mobileDetail => 
        mobileDetail.label === subNavigator.productDetail?.heading ||mobileDetail.label === subNavigator.labelText);
      if (sameLabelAlreadyExists) {
        continue;
      }

      if (subNavigator.productDetail) {
        mobileDetailItems.push({
          label: subNavigator.productDetail.heading,
          link: createProductUrl(subNavigator.productDetail.heading)
        });
      } else { //this means it is a template instead of a single product
        mobileDetailItems.push({
          label: subNavigator.labelText,
          link: createListUrl(subNavigator.id, subNavigator.identifier)
        });
      }
    }

    return {
      ...data,
      items: mobileDetailItems
    } as MobileDetail;
  }

  private getSubNavigators(allNavigators: Navigator[], navigator: Navigator): SubNavigator[] {
    let allSubNavigators = [] as unknown[];

    //if there are child navigators then we need to recurisvely go through..
    //until we reach the subnavigators
    if (navigator.childNavigators && navigator.childNavigators.length > 0) {
      const subNavigators = navigator.childNavigators
        .map(childNavigator => {
          //the "hydrated" child navigator has the actual subNavigators on the object..
          //those are needed to create the links for the mobile details
          const hyrdatedChildNavigator = allNavigators.find(n => n.id === childNavigator.id);
          return this.getSubNavigators(allNavigators, hyrdatedChildNavigator ?? childNavigator)
        })
        .flat();
      allSubNavigators = allSubNavigators.concat(subNavigators);
    } else if (navigator.subNavigators && navigator.subNavigators.length > 0) {
      allSubNavigators = allSubNavigators.concat(navigator.subNavigators);
    }

    return allSubNavigators as SubNavigator[];
  }

  private createRouteId(navigatorId: number): string {
    return `id-${navigatorId}`;
  }

  public handleUpcomingEventsClick(): void {
    this.customTracker("Upcoming Events - Button Mobile");
    this.$router.push("/events");
  }

  public handleNewsClick(): void {
    this.customTracker("News - Button Mobile");
    this.$router.push("/news");
  }

  public handleNewProductsClick(): void {
    this.customTracker("New Products - Button Mobile");
    this.$router.push("/new-products");
  }

  public handleSeeAllInnovationsClick(): void {
    this.customTracker("See All Innovations - Button Mobile");
    this.$router.push("/list/innovations");
  }

  setLocale(localeName: string): void {
    const locale = this.locales.find((locale) => locale.name === localeName);
    this.localeService.setLocale(locale as Locale);
    this.selectedLocaleName = localeName;
    location.href = "/mobile";
  }

  setLanguage(languageName: string): void {
    const language = this.languages.find(
      (language) => language.name === languageName
    );
    this.selectedLanguageName = language?.name ?? 'English';
    this.languageService.setDCSLanguage(language as DCSLanguage);
    location.reload();
  }
}
